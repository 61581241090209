import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Modal,
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "../component/home.css";
import { Helmet } from "react-helmet";
import { getcmsAction } from "../Action/user.action";
import Wfooter from "../directives/Wfooter";

const PrivacyPolicy = () => {
  const [privacypolicylist, setprivacypolicylist] = useState({
    privacy_policy:""
  });
  useEffect(() => {
    getprivacypolicyfunc();
    // setcondition();
  }, []);

  const getprivacypolicyfunc = async () => {
    let res = await getcmsAction("privacypolicy");
    if (res.success) {
      setprivacypolicylist(res.data);
    } else {
    }
  };

  // const navigateToPackagePlan = () => {

  //   navigate(`${config.baseUrl}packageplan`)
  // }

  return (
    <>
      <div className="lighttheme bg-white overflow-hidden hero">
        <section className="herobanner position-relative text-white overflow-hidden" style={{backgroundColor:"black"}}>
          <Container>
            <Row className="align-items-center">
              {console.log(privacypolicylist.privacy_policy)}
              <div className="jovitClass"
                dangerouslySetInnerHTML={{
                  __html: privacypolicylist.privacy_policy,
                }}
              />
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};
export default PrivacyPolicy;
