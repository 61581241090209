import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { Container, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import Tree from "react-d3-tree";
import { TextField, Box } from "@mui/material";
import "../custom-tree.css";

import Dashboardheader from "../directives/dashboardheader";
import { ProfileUpdateAction, UserProfile } from "../Action/user.action";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Cookies from "js-cookie";

const Profile = () => {
  const [userdata, setuserdata] = useState({});
  const [initialFormData, setInitialFormData] = useState({
    first_name: '',
    last_name: '',
    bio: '',
  });

  const [image_preview, setimage_preview] = useState('');
  const [image_file, setimage_file] = useState('');
  const [email, setemail] = useState('')
  useEffect(() => {
    getStepMintProfile();
    console.log(userdata);
    // setcondition();
  }, [])

  const setcondition = async () =>{
    initialFormData.first_name = userdata.first_name
    initialFormData.last_name = userdata.last_name
    initialFormData.bio = userdata.bio

  }

  const inputHandler = (e) => {
    const { name, value } = e.target
    setuserdata((old) => {
      return { ...old, [name]: value }
    })
  }

  const profilePicChange = async (e) => {
    e.preventDefault()
    let image_as_base64 = URL.createObjectURL(e.target.files[0])
    let image_as_files = e.target.files[0];
    setimage_file(image_as_files);
    setimage_preview(image_as_base64);
  }


  const logout = async () => {
    Cookies.remove('loginSuccessStepMrMint');
    Cookies.remove('loginSuccessMrMint', { domain: 'mrmint.io' });
    window.localStorage.clear();
    setTimeout(() => {
      window.location.href = `${config.baseUrl}`
    });
  }

  const handleChange = (e) => {
    e.preventDefault();
  };

  const SubmitForm = async (e) => {
    e.preventDefault()
    // if ( initialFormData.first_name == userdata.first_name && initialFormData.last_name == userdata.last_name && initialFormData.bio == userdata.bio) {
    //   toast.error('Nothing to update, data remains unchanged.')
    // } else {
    let old_profile_pic = '';
    if (!image_file) {
      old_profile_pic = userdata?.profile_pic ? userdata?.profile_pic : ''
    }

    let userDatawithoutemail = {
      'first_name': userdata?.first_name ? userdata?.first_name : '',
      'last_name': userdata?.last_name ? userdata?.last_name : '',

      'bio': userdata?.bio ? userdata?.bio : '',
      'profile_pic': image_file,
      'old_profile_pic': old_profile_pic,
      'whichquerytorun': "updateuser_without_email"
    }
    let userDatawithemail = {
      'first_name': userdata?.first_name ? userdata?.first_name : '',
      'last_name': userdata?.last_name ? userdata?.last_name : '',
      'email': userdata.email,
      'bio': userdata?.bio ? userdata?.bio : '',
      'profile_pic': image_file,
      'old_profile_pic': old_profile_pic,
      'whichquerytorun': "updateuser_with_email"
    }
    let userData;
    if (email == userdata.email) {
      userData = userDatawithoutemail
    } else {
      userData = userDatawithemail
    }
    let res2 = await ProfileUpdateAction(userData);
    if (res2.success) {
      toast.success(res2.msg);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    else {
     if (res2.msg=="Unable to update data"){
      toast.error("No changes made. Please modify before updating.");
     }
     else {
      toast.error(res2.msg)
     }
    }
  // }
  }


  const getStepMintProfile = async () => {
    let res = await UserProfile();
    if (res.success) {
      setuserdata(res.data)
    }
    else {

    }
  }
  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">

          <div className=" mb-5">
            <Container className="">
              <Row>
                <Col lg={2}></Col>
                <Col lg={8}>
                  <div className="card card-view rounded-20 p-4">
                    <div className="card-body p-3">
                      {/* <Row className=""> */}
                      {/* <Col lg={3} className="mb-3">
                          <div>

                            <div className="profile card-view rounded-20">
                              <h4 className="text-white mb-0">Profile image</h4>
                              <div className='profile'>

                                {image_preview ?
                                  <img style={{ height: '190px', width: '190px', objectFit: 'cover' }} alt='' src={image_preview} className='d-profile-img-edit img-fluid' />
                                  :
                                  !userdata?.profile_pic || userdata?.profile_pic == undefined || userdata?.profile_pic == 'undefined' ?
                                    <img alt='' style={{ height: '190px', width: '190px', objectFit: 'cover' }} src="https://starwallets.io/images/default-user-icon.jpg" className='d-profile-img-edit img-fluid' />
                                    :
                                    <img alt='' style={{ height: '190px', width: '190px', objectFit: 'cover' }} src={`${config.imageUrl + userdata?.profile_pic}`} className='d-profile-img-edit img-fluid' />
                                }
                                <input onChange={profilePicChange} type="file" id="upload_profile_img" />
                              </div>

                            </div>

                          </div>
                        </Col> */}
                      <Col lg={12} className="mb-3">
                        <div className="card-view rounded-20 p-4">
                          <Row>
                            <Col lg={12} className="mb-4">
                              <div className="mb-4">
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Control onChange={inputHandler} name='first_name' value={userdata?.first_name ? userdata?.first_name : ''} type="name" placeholder="First Name" />
                                </Form.Group>
                              </div>
                              <div className="mb-4">
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Control onChange={inputHandler} name='last_name' value={userdata?.last_name ? userdata?.last_name : ''} type="name" placeholder="Last Name" />
                                </Form.Group>
                              </div>
                              <div className="mb-4">
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control onChange={inputHandler} name='email' value={userdata?.email ? userdata?.email : ''} type="email" disabled placeholder="Email" />
                                </Form.Group>
                              </div>
                              <div className="mb-4">
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>Bio (Max 255 words)</Form.Label>
                                  <Form.Control onChange={inputHandler} name='bio' value={userdata?.bio ? userdata?.bio : ''} as="textarea" className="h-auto" rows={3} placeholder="Tell the world who you are!" />
                                </Form.Group>
                              </div>
                              <Button variant="main2" onClick={SubmitForm} className=" text-white">
                                Update Profile
                              </Button>
                            </Col>

                          </Row>
                        </div>
                      </Col>
                      {/* </Row> */}
                    </div>
                  </div>
                </Col>
                <Col lg={2}></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Profile;
