import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Accordion,
  Form
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import { TextField, FormGroup, FormControlLabel, Checkbox } from "@mui/material";

const Forgetpassword = () => {
  return (
    <>
      <Header />

      <section className="position-relative overflow-hidden signup">
      <div className="shape_bg">
          <img src="assets/images/shape.svg" className="shape" />
          <img src="assets/images/shape1.svg" className="shape1" />
          <img src="assets/images/shape2.svg" className="shape2" />
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={5} className="">
              <Zoom>
              <div className="signup_form">
                <Container className="pt-4 pb-4">
                  <Row>
                    <Col lg={12} className=" mb-2">
                      <div>
                        <h3 className="text-white"><strong>Forgot Password</strong></h3>
                        <small className="text-light-grey">Enter the email or phone number associated with your account, and we’ll email you the verification code to reset your password.</small>
                      </div>
                    </Col>
                    
                    <Col lg={12} className=" mt-4 mb-4">
                       <Form.Group controlId="formBasicEmail">
                          <Form.Control type="email" placeholder="Email / Phone Number" />
                        </Form.Group>
                    </Col>
                   
                   
                    <Col lg={12} className="mt-2 mb-3">
                   
                          <div>
                            <button
                              type="button"
                              className="text-white px-5  btn btn-main2 w-100"
                            >
                              Continue
                            </button>
                          </div>
                    
                    </Col>
                  </Row>
                </Container>
              </div>
              </Zoom>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};
export default Forgetpassword;
