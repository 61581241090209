import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  Tab,
  Nav,
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import Dashboardheader from "../directives/dashboardheader";
import {
  MatchingTeamAndNodeList,
  ShowReferralHistory,
  showsteplevelrewardAction,
} from "../Action/user.action";
import Cookies from "js-cookie";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";

const Matchingbonus = () => {
  const [getreferral, setreferral] = useState([]);
  const [getnodelist, setnodelist] = useState([]);
  const [getteamlist, setteamlist] = useState([]);
  const [royaltybonuslist, setroyaltybonuslist] = useState([]);

  const [getreferralcount, setreferralcount] = useState("");
  const [gettotalamountmnt, settotalamountmnt] = useState("");
  const [gettotalamountusdt, settotalamountusdt] = useState("");
  const [teamPurchase, setteamPurchase] = useState("");

  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
        Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
      );

  const itemsPerPage = 10; // Set the number of items per page
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = ({ selected }) => {
    console.log(selected, "selected");
    setCurrentPage(selected);
  };
  const CurrentPageResetFunc = () => {
    setCurrentPage(0);
    handlePageChange({ selected: 0 });
  };
  const isOnFirstPage = currentPage == 0;
  const totalPages = Math.ceil(getreferral.length / itemsPerPage);
  const isOnLastPage = currentPage == totalPages - 1;
  const totalPagesNodeList = Math.ceil(getnodelist.length / itemsPerPage);
  const isOnLastPageNodeList = currentPage == totalPagesNodeList - 1;
  const totalPagesteamlist = Math.ceil(getteamlist.length / itemsPerPage);
  const isOnLastPageteamlist = currentPage == totalPagesteamlist - 1;
  const totalPagesroyaltybonuslist = Math.ceil(royaltybonuslist.length / itemsPerPage);
  const isOnLastPageroyaltybonuslist = currentPage == totalPagesroyaltybonuslist - 1;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const getreferralSliced = getreferral.slice(startIndex, endIndex);
  const getnodelistSlice = getnodelist.slice(startIndex, endIndex);
  const getteamlistSlice = getteamlist.slice(startIndex, endIndex);
  const royaltybonuslistSlice = royaltybonuslist.slice(startIndex, endIndex);

  useEffect(() => {
    ReferralFunc();
    showsteplevelFunc();
    showmatchingandnodelistFunc();
  }, []);

  const copieBtn = async () => {
    toast.success(`Copied!!`);
  };

  const showsteplevelFunc = async () => {
    let res2 = await showsteplevelrewardAction();
    if (res2.success) {
      setroyaltybonuslist(res2.data);
      console.log("setroyaltybonuslist", res2.data);
    } else {
    }
  };

  const showmatchingandnodelistFunc = async () => {
    let res1 = await MatchingTeamAndNodeList();
    if (res1.success) {
      setnodelist(res1.data.nodeList);
      setteamlist(res1.data.teamList);
      console.log(res1.data);
    } else {
    }
  };

  const ReferralFunc = async () => {
    let res = await ShowReferralHistory(2);
    // let res2 = await showsteplevelrewardAction();
    // let res1 = await MatchingTeamAndNodeList();
    if (res.success) {
      setreferral(res.data.history);
      setreferralcount(res.data.statistics.TeamCount);
      setteamPurchase(res.data.statistics.teamPurchase);
      let totalamount = 0;
      for (let i = 0; i < res.data.history.length; i++) {
        totalamount += parseFloat(res.data.history[i].amount);
      }
      settotalamountmnt(totalamount);
      let totalamountUSDT = 0;
      for (let i = 0; i < res.data.history.length; i++) {
        totalamountUSDT += parseFloat(res.data.history[i].usdAmount);
      }
      settotalamountusdt(totalamountUSDT);
    } else {
      setreferral([]);
    }
    // if (res1.success) {
    //   setnodelist(res1.data.nodeList)
    //   setteamlist(res1.data.teamList)
    //   console.log(res1.data);
    // } else {

    // }
    // if (res2.success) {
    //   setroyaltybonuslist(res2.data)
    //   console.log("setroyaltybonuslist",res2.data);
    // } else {

    // }
  };
  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          <div className="mb-3">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <h2 className="text-white">Matching Bonus </h2>
                </Col>
              </Row>
            </Container>
          </div>
          {/* -------------------- */}

          <div className=" mb-5">
            <div className="container referral">
              <div className="mr-referral-content">
                <div className="mr-referral-block">
                  <Row>
                    <Col lg={4} md={12} className="">
                      <div
                        className="mr-referral-carditem mr-referralref-item buyform card-view1 p-5 px-3 mb-4"
                        style={{ minHeight: "348px" }}
                      >
                        <div className="mr-referral-inner">
                          <div className="mr-contant-block">
                            <h4>
                              Team Size<span className="seprator"> : </span>
                              <span className="text">{getreferralcount}</span>
                            </h4>
                            <h4>
                              Team Purchase<span className="seprator"> : </span>
                              <span className="text">
                                ${teamPurchase == null ? 0 : teamPurchase}
                              </span>
                            </h4>
                            <h4 className="mb-0">
                              Total Income<span className="seprator"> : </span>
                              <span className="text text-white">
                                {/* $0.00 */}$
                                {parseFloat(gettotalamountusdt)?.toFixed(2)}
                              </span>
                            </h4>                          </div>
                        </div>
                      </div>
                      {/* <div className="mr-referral-carditem mr-referralref-item buyform card-view1 p-4 px-3">
                        <div className="mr-referral-inner">
                          <div className="mr-contant-block">
                            <h4 className="mb-0">
                              Rank <span className="seprator"> : </span>
                              <span className="text">Achieve to quality</span>
                            </h4>
                          </div>
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={8} md={12} className=" ">
                      <div className="mr-referral-carditem mr-referral-item buyform mb-4 card-view1 p-4">
                        <div className="mr-contant-block">
                          <h4>Matching bonus</h4>
                          <img src="assets/images/mlm_tree.png" width="100%" />
                          {/* <ul className="mr-referralsteps">
                        
                        
                          <li className="mr-referralsteps-item mr-referral-item1">
                              <div className="mr-stepsinfo">
                                <div className="mr-inner-stepinfo">
                                 

                                  <img src="assets/images/p2.png" width="90px" />
                                </div>
                               
                              </div>
                            </li>
                            <li className="mr-referralsteps-item mr-referral-item2">
                              <div className="mr-stepsinfo">
                                <div className="mr-inner-stepinfo">
                                  
                                  <img src="assets/images/p3.png" width="90px" />
                                </div>
                              </div>
                            </li>
                            <li className="mr-referralsteps-item mr-referral-item3">
                              <div className="mr-stepsinfo">
                                <div className="mr-inner-stepinfo">
                                 

                                  <img src="assets/images/p3.png" width="90px" />
                                </div>
                              
                              </div>
                            </li>
                          
                          </ul> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          {/* ---------- */}
          {/* <div className="mb-4">
            <Container className="">
              <Row className="mb-3">
                <Col lg={12}>
                  <h2 className="text-white">
                    Invite Friends to Earn Together
                  </h2>
                </Col>
              </Row>
            </Container>
          </div> */}
          {/* ---------- */}
          <div className="latest_transaction mb-5">
            <Container>
              <Tab.Container id="left-tabs-example" defaultActiveKey="teamlist">
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills" className="">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="teamlist"
                          className="card-view1"
                          onClick={CurrentPageResetFunc}
                        >
                          Team List
                        </Nav.Link>
                      </Nav.Item>
                      &emsp;
                      <Nav.Item>
                        <Nav.Link
                          eventKey="nodes"
                          className="card-view1"
                          onClick={CurrentPageResetFunc}
                        >
                          Nodes
                        </Nav.Link>
                      </Nav.Item>
                      &emsp;
                      <Nav.Item>
                        <Nav.Link
                          eventKey="bonushistory"
                          className="card-view1"
                          onClick={CurrentPageResetFunc}
                        >
                          Bonus History
                        </Nav.Link>
                      </Nav.Item>
                      &emsp;
                      <Nav.Item>
                        <Nav.Link
                          eventKey="royaltybonus"
                          className="card-view1"
                          onClick={CurrentPageResetFunc}
                        >
                          Loyalty Bonus
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12} className="mt-3">
                    <Tab.Content>
                      <Tab.Pane eventKey="teamlist">
                        <div className="table-responsive">
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th>S.No</th>
                                <th>Email</th>
                                <th>Referred By</th>
                                <th>Purchase</th>
                                <th>Joining Date</th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {getteamlist.length == 0 ? (
                                <tr className="tr_list">
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                  <td colspan="5">No Team Available</td>
                                  {/* <td></td> */}
                                </tr>
                              ) : (
                                getteamlistSlice.map((item, index) => (
                                  <tr className="tr_list">
                                    <td>
                                      {index + 1 + currentPage * itemsPerPage}
                                    </td>{" "}
                                    <td>{item.email}</td>
                                    <td>{item.referred_by}</td>
                                    <td>{item.totalPurchase}</td>
                                    <td>{item.created_at}</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                          {getteamlist.length > itemsPerPage && (
                            <div className="pagination-container">
                              <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                previousClassName={
                                  isOnFirstPage ? "btn  disabled" : ""
                                }
                                nextClassName={
                                  isOnLastPageteamlist ? "btn  disabled" : ""
                                }
                                previousLinkClassName={
                                  isOnFirstPage ? "disabled-link" : ""
                                }
                                nextLinkClassName={
                                  isOnLastPageteamlist ? "disabled-link" : ""
                                }
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  getteamlist.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={currentPage}
                              />
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="nodes">
                        <div className="table-responsive">
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th>S.No</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th>Total Business</th>
                                <th>Remaining Amount</th>
                                <th>Joining Date</th>
                                {/* <th>Team List</th> */}
                              </tr>
                            </thead>
                            <tbody className="">
                              {getnodelist.length == 0 ? (
                                <tr className="tr_list">
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                  <td colspan="6"> No Data Available</td>
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                </tr>
                              ) : (
                                getnodelistSlice.map((item, index) => (
                                  <tr className="tr_list">
                                    <td>
                                      {index + 1 + currentPage * itemsPerPage}
                                    </td>{" "}
                                    <td>{item.email}</td>
                                    <td>
                                      {" "}
                                      <span className="text-gradient">
                                        <b>
                                          {" "}
                                          <span class="text-sm text-bold text-light">
                                            {item.bnb_address?.slice(0, 4) +
                                              "...." +
                                              item.bnb_address?.slice(-4)}{" "}
                                          </span>
                                          <CopyToClipboard
                                            text={item.bnb_address}
                                          >
                                            <span
                                              title="Click to Copy"
                                              className="mr-copylink"
                                              id="token-buy-button"
                                              onClick={copieBtn}
                                              style={{
                                                cursor: "pointer",
                                                color: "rgb(157 81 255)",
                                              }}
                                            >
                                              <i class="fa fa-clone "></i>
                                            </span>
                                          </CopyToClipboard>
                                        </b>
                                      </span>
                                    </td>
                                    <td>${item.totalBusiness}</td>
                                    <td>${item.remainingBalance}</td>
                                    <td>{item.created_at}</td>
                                    {/* <td><Button variant="main2">View</Button></td> */}
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                          {getnodelist.length > itemsPerPage && (
                            <div className="pagination-container">
                              <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                previousClassName={
                                  isOnFirstPage ? "btn  disabled" : ""
                                }
                                nextClassName={
                                  isOnLastPageNodeList ? "btn  disabled" : ""
                                }
                                previousLinkClassName={
                                  isOnFirstPage ? "disabled-link" : ""
                                }
                                nextLinkClassName={
                                  isOnLastPageNodeList ? "disabled-link" : ""
                                }
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  getnodelist.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={currentPage}
                              />
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="bonushistory">
                        <div className="table-responsive">
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th>S.No</th>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Matching Bonus Percent</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {getreferral.length == 0 ? (
                                <tr className="tr_list">
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                  <td colspan="6">No History Available</td>
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                </tr>
                              ) : (
                                getreferralSliced.map((item, index) => (
                                  <tr className="tr_list">
                                    <td>
                                      {index + 1 + currentPage * itemsPerPage}
                                    </td>{" "}
                                    <td>{item.datetime}</td>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <td>
                                      {item.referralPercent == null
                                        ? "NA"
                                        : item.referralPercent}
                                      %
                                    </td>
                                    <td>
                                      ${parseFloat(item.usdAmount)?.toFixed(2)}~
                                      {parseFloat(item.amount)?.toFixed(2)}
                                      &nbsp;MNT
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                          {getreferral.length > itemsPerPage && (
                            <div className="pagination-container">
                              <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                previousClassName={
                                  isOnFirstPage ? "btn  disabled" : ""
                                }
                                nextClassName={
                                  isOnLastPage ? "btn  disabled" : ""
                                }
                                previousLinkClassName={
                                  isOnFirstPage ? "disabled-link" : ""
                                }
                                nextLinkClassName={
                                  isOnLastPage ? "disabled-link" : ""
                                }
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  getreferral.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={currentPage}
                              />
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="royaltybonus">
                        <div className="table-responsive">
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th>S.No</th>
                                <th>Date</th>
                                <th>Unique NftID</th>
                                <th>Level</th>
                                <th>Percent</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {royaltybonuslist.length == 0 ? (
                                <tr className="tr_list">
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                  <td colspan="6">No History Available</td>
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                </tr>
                              ) : (
                                royaltybonuslistSlice.map((item, index) => (
                                  <tr className="tr_list">
                                    <td>
                                      {index + 1 + currentPage * itemsPerPage}
                                    </td>{" "}
                                    <td>{item.date?.slice(0, 10)}</td>
                                    <td>{item.uniqueId}</td>
                                    <td>{item.level}</td>
                                    <td>
                                      {item.percent == null
                                        ? "NA"
                                        : item.percent}
                                      %
                                    </td>
                                    <td>
                                      {parseFloat(item.amount).toFixed(4)}
                                      &nbsp;MNT
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                          {royaltybonuslist.length > itemsPerPage && (
                            <div className="pagination-container">
                              <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                previousClassName={
                                  isOnFirstPage ? "btn  disabled" : ""
                                }
                                nextClassName={
                                  isOnLastPageroyaltybonuslist ? "btn  disabled" : ""
                                }
                                previousLinkClassName={
                                  isOnFirstPage ? "disabled-link" : ""
                                }
                                nextLinkClassName={
                                  isOnLastPageroyaltybonuslist ? "disabled-link" : ""
                                }
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  royaltybonuslist.length / itemsPerPage
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={currentPage}
                              />
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Container>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Matchingbonus;
