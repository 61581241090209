import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Nav,
  Tab,
  Modal,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import Dashboardheader from "../directives/dashboardheader";
import Cookies from "js-cookie";
import { NavLink } from "react-router-dom";
import "../component/dashboard.css";
import {
  UserPurchasedPlan,
  DashboardStatistics,
  checkmintingofNFTAction,
} from "../Action/user.action";
import { Vortex } from "react-loader-spinner";

import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import Web3 from "web3";

export default function MintNft() {
  const [userdata, setuserdata] = useState([]);
  const [MintDetails, setMintDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(
    "Your Minting is processing"
  );
  const [MintingTimer, setMintingTimer] = useState("");

  const [showModalLoad, setShowModalLoad] = useState(false);

  const [showMessage, setshowMessage] = useState(false);

  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
    );

  useEffect(() => {
    UsersPlan();
  }, []);

  const copieBtn = async () => {
    toast.success(`Copied!!`);
  };

  let { id } = useParams();
  console.log("id", id);

  const UsersPlan = async () => {
    let res1 = await UserPurchasedPlan();
    if (res1.success) {
      if (res1.data == null) {
        setuserdata([]);
      } else {
        setuserdata(res1.data.myplan);
        // isMintFunc();
      }
    } else {
      setuserdata([]);
    }
  };

  const redirect = () => {
    setshowMessage(false);
    window.location.reload();
  };

  const isMintFunc = async () => {
    setShowModal(false);
    setShowModalLoad(true);
    try {
      if (window.ethereum) {
        let web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        let from_address = accounts[0];
        if (loginData.bnb_address.toLowerCase() != from_address.toLowerCase()) {
          toast.error(`Please connect with registered wallet`);
          setShowModalLoad(false);
          return false;
        }
        let chainId = await web3.currentProvider.chainId;
        if (!chainId) {
          chainId = web3.currentProvider.getChainId();
        }
        if (chainId != config.chainId && web3.utils.toHex(parseInt(chainId)) != config.chainId) {
          toast.error(`Please select BNB smartchain!!`);
          setShowModalLoad(false);
          return false;
        } else {
          console.log({ nftId: id });
          let checkNft = await checkmintingofNFTAction(id);
          if (!checkNft.success) {
            toast.error(`You don't have NFT for mint!`);
            setShowModalLoad(false);
            return;
          }

          let MrmintContractCall = new web3.eth.Contract(
            config.StackContractABI,
            config.StackContractAddress
          );

          setMintDetails(checkNft.data);
          let minting = await MrmintContractCall.methods.mintAndStakeNFTbyUser(
            checkNft.data._nftType.toString(),
            checkNft.data._timestamp.toString(),
            checkNft.data.signature.toString()
          );
          let encoded_tx = minting.encodeABI();
          let gasPrice = await web3.eth.getGasPrice();
          let gasLimit = await web3.eth.estimateGas({
            gasPrice: web3.utils.toHex(gasPrice),
            to: config.StackContractAddress,
            from: loginData?.bnb_address,
            data: encoded_tx,
          });

          let trx = await web3.eth.sendTransaction({
            gasPrice: web3.utils.toHex(gasPrice),
            gas: web3.utils.toHex(gasLimit),
            to: config.StackContractAddress,
            from: loginData?.bnb_address,
            data: encoded_tx,
          });
          console.log(trx);

          if (trx.transactionHash) {
            setShowModalMessage("Waiting to reflect in our server...");
            toast.success("Minting Successful");
            let a = 120;
            setInterval(() => {
              a -= 1;
              setMintingTimer(a);
            }, 1000);
            setTimeout(() => {
              setShowModal(false);
              setShowModalLoad(false);
              setshowMessage(true);
            }, 120000);

            return true;
          } else {
            toast.error("Something went wrong");
            setShowModal(false);
            setShowModalLoad(false);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
            return false;
          }
        }
      } else {
        setShowModalLoad(false);
        toast.error("Please use Dapp browser!!");
      }
    } catch (error) {
      console.log(error);
      setShowModalLoad(false);
      switch (error.code) {
        case 4001:
          toast.error("User Rejected");
          break;
        case -32603:
          toast.error(
            "Insufficient Gas fees"
          );
          break;
        case -32002:
          toast.error(
            "Request Already Processing, Please check your Meta Mask wallet"
          );
          break;
        case "ERR_NETWORK":
          toast.error("We're experiencing technical difficulties.");
          break;
        default:
          break;
      }
      setShowModal(false);
    }
  };

  return (
    <>
      <Toaster />
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Container className="">
          <Row>
            {userdata
              .filter((item) => item.uniqueId == id)
              .map((item, index) => (
                <>
                  <Col lg={5}>
                    <div className="card nftplan">
                      <div className="card-body p-0">
                        <div className=" p-3 pb-1">
                          <div>
                            <table width="100%">
                              <tr>
                                <th className="">
                                  <h5 className="mb-0 text-white">
                                    {item.name}
                                  </h5>
                                </th>
                                <td className="">
                                  <div>
                                    <Button
                                      variant="main2"
                                      className="rounded-1 btn-sm mh-auto pt-2 pb-2 px-4"
                                    >
                                      NFT-Id&nbsp;{item.uniqueId}
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th className="">
                                  {" "}
                                  <p className="mb-0">${item.priceUSD}</p>
                                </th>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div className="card-view text-center p-2 rounded-30 customcarView" style={{ minHeight: "227" }}>
                          <img
                            src={`${config.ipfsurl + item.image}`}
                            alt={item.image}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={7}>
                    <div className="package_detail">
                      <ul className="">
                        <li>
                          <div className="d-flex justify-content-between">
                            <div>Type of NFT</div>
                            <p className="mb-0">
                              {item.editionTypeId == 1
                                ? "Basic Nft"
                                : item.editionTypeId
                                  ? "Limited Edition"
                                  : "Unkonwn Status"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            <div>
                              Subscription Period{" "}
                              {/* <small>(Starts after App launch)</small> */}
                            </div>
                            <p className="mb-0">{item.expiryDate}</p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            <div>
                              Capacity <small>(Steps)</small>
                            </div>
                            <p className="mb-0">{parseInt(item.capacity)} </p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            <div>Per Day Reward</div>
                            <p className="mb-0">upto {item.rewardPercent}%</p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            <div>Per Month Reward</div>
                            <p className="mb-0">
                              upto {(31 * item.rewardPercent)?.toFixed(2)}%
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <div className="package_detail">
                    <ul className="">
                      <div className="row">
                        <div className="col-lg-5">
                          <li>
                            <div className="d-flex justify-content-between">
                              <div>Nft Number</div>
                              <p className="mb-0">{item.packagePlanId}</p>
                            </div>
                          </li>
                        </div>
                        <div className="col-lg-7">
                          <div className="row claim_btn">
                            <div className="col-lg-6">
                              <li>
                                <div className="d-flex justify-content-between">
                                  <div>{item.isminted == 0 ? "Mint NFT" : "Blockchain View"}</div>
                                  <div>
                                    {item.isminted == 0 ? (
                                      <Button
                                        variant="main2"
                                        onClick={isMintFunc}
                                        className="rounded-1 btn-sm mh-auto pt-2 pb-2 px-4"
                                      >
                                        Mint now
                                      </Button>
                                    ) : (
                                      <div className="d-flex">
                                        <p className="mb-0 d-flex">
                                          <a
                                            className="mb-0"
                                            target="_blank"
                                            href={`${item.trxHash}`}
                                            style={{ textDecoration: "none" }}
                                          >
                                            {item.trxHash.slice(23, 4) +
                                              "...." +
                                              item.trxHash.slice(-4)}
                                          </a>
                                          &emsp;&nbsp;
                                          <CopyToClipboard text={item.trxHash}>
                                            <div>
                                              <i
                                                class="fa fa-clone"
                                                title="Click to Copy"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#FFF",
                                                }}
                                                onClick={copieBtn}
                                              ></i>
                                            </div>
                                          </CopyToClipboard>
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                            </div>
                            <div className="col-lg-6">
                              <li>
                                <div className="d-flex justify-content-between">
                                  <div>Claim NFT</div>
                                  <div>
                                    <Button
                                      variant="main2"
                                      className="rounded-1 btn-sm mh-auto pt-2 pb-2 px-4"
                                      disabled
                                    >
                                      Coming Soon
                                    </Button>
                                  </div>
                                </div>
                              </li>
                            </div>
                          </div>
                        </div>
                      </div>



                      {/* <li>
                        <div className="d-flex justify-content-between">
                          <div className="text-warning">Note:-&emsp;&nbsp;</div>
                          <div>
                            NFT minting takes a bit of time to show up in our
                            system. Once it's done, use this Contract Address{" "}
                            {config.contractAddressForMinting?.slice(0, 4) +
                              "...." +
                              config.contractAddressForMinting?.slice(-4)}
                            &nbsp;
                            <CopyToClipboard
                              text={config.contractAddressForMinting}
                            >
                              <i
                                class="fa fa-clone"
                                title="Click to Copy"
                                style={{
                                  cursor: "pointer",
                                  color: "#FFF",
                                }}
                                onClick={copieBtn}
                              ></i>
                            </CopyToClipboard>{" "}
                            &nbsp; to bring it in, and put your NFT Number i.e
                            for this Nft its ({item.packagePlanId}) into your
                            Metamask wallet. Thanks for your patience!
                          </div>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </>
              ))}
          </Row>
        </Container>
      </div>
      {/* --------------------- */}
      <Modal
        show={showModal}
        className="walker_detail"
        centered
        size="l"
        backdrop="static"
      >
        <Modal.Body>
          <Row>
            <Vortex
              visible={showModal == true ? true : false}
              height="80"
              width="80"
              ariaLabel="vortex-loading"
              wrapperStyle={{}}
              wrapperClass="vortex-wrapper"
              colors={[
                "#6610f2;",
                "#6f42c1",
                "blue",
                "#300d39",
                "#212529",
                "purple",
              ]}
            />
            <div className="" style={{ display: "grid" }}>
              <p style={{ margin: "auto" }}>Please wait.....</p>
              <p style={{ margin: "auto" }}>{showModalMessage}</p>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      {/* ----------------- */}

      {/* --------------------- */}
      <Modal
        show={showMessage}
        className="walker_detail"
        centered
        size="l"
        backdrop="static"
      >
        <Modal.Body>
          <Row>
            <div className="" style={{ display: "grid" }}>
              <p style={{ margin: "auto" }}>Your Minting is in Progress</p>
              <p style={{ margin: "auto" }}>
                It will take some time to reflect in your account
              </p>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="secondary" onClick={redirect}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ----------------- */}

      {/* --------------------- */}
      <Modal
        show={showModalLoad}
        className="walker_detail"
        centered
        size="l"
        backdrop="static"
      >
        <Modal.Body>
          <Row>
            <Vortex
              visible={showModalLoad == true ? true : false}
              height="80"
              width="80"
              ariaLabel="vortex-loading"
              wrapperStyle={{}}
              wrapperClass="vortex-wrapper"
              colors={[
                "#6610f2;",
                "#6f42c1",
                "blue",
                "#300d39",
                "#212529",
                "purple",
              ]}
            />
            <div className="" style={{ display: "grid" }}>
              <p style={{ margin: "auto" }}>
                Please wait.....{MintingTimer && `${MintingTimer} Seconds`}{" "}
              </p>
              <p style={{ margin: "auto" }}>{showModalMessage}</p>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      {/* ----------------- */}
    </>
  );
}
