import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../config/config";
import { Container, Row, Col, Button, Card, Tab, Nav } from "react-bootstrap";

const Mobilefooter = () => {
    return (
        <>
            <section className="mobile_footer position-relative text-white overflow-hidden py-0">
                <Container>
                    
                    <Row className="justify-content-center">
                        <Col lg={4} className="mobile_footerinr py-3">
                            <Container className="">
                                <Row>
                                    <Col lg={3} md={3} xs={3} className="px-0">
                                        <div className="f_box active">
                                            <div className="">
                                                <img src="assets/images/running-solid-icon.svg" className="f_icons mb-2" />
                                                <p>Dashboard</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} xs={3} className="px-0">
                                        <div className="f_box">
                                            <div className="">
                                                <img src="assets/images/f_activity.svg" className="f_icons mb-2" />
                                                <p>Activity</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} xs={3} className="px-0">
                                        <div className="f_box">
                                            <div className="">
                                                <img src="assets/images/f_trophy.svg" className="f_icons mb-2" />
                                                <p>Leaderboard</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} xs={3} className="px-0">
                                        <div className="f_box">
                                            <div className="">
                                                <img src="assets/images/f_cart.svg" className="f_icons mb-2" />
                                                <p>NFT Package</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>

            </section>
        </>
    )
}

export default Mobilefooter;
