import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import { GetLdp2Action, GetOtpAction, UserProfile } from "../Action/user.action";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { TbPasswordUser } from "react-icons/tb";
import QRCodeModal from "../component/Modals/QRCodeModal";

const Dashboardheader = () => {
  const loginData = (!Cookies.get('loginSuccessStepMrMint', { domain: 'mrmint.io' })) ? [] : JSON.parse(Cookies.get('loginSuccessStepMrMint', { domain: 'mrmint.io' }));
  const [userdata, setuserdata] = useState([])
  const [lastparturl, setlastparturl] = useState([]);
  let navigate = useNavigate();


  const getStepMintProfile = async () => {
    let res = await UserProfile();
    if (res.success) {
      setuserdata(res.data)
    }
    else {
      setuserdata([])
    }
  }
  useEffect(() => {
    dynamicLink();
    getStepMintProfile();
    // getQrCodeData();
    
    if (!loginData.id) {
      window.location.href = `${config.baseUrl}`
    }
  }, [])

  const dynamicLink = () => {
    let url = window.location.href;
    const parts = url.split('/');
    const lastPart = parts[parts.length - 1];
    setlastparturl(lastPart)
  }

  const logout = async () => {
    Cookies.remove('loginSuccessStepMrMint');
    Cookies.remove('loginSuccessMrMint', { domain: 'mrmint.io' });
    window.localStorage.clear();
    setTimeout(() => {
        window.location.href = `${config.baseUrl}`
    });
}

  const copieBtn = async () => {
    toast.success(`Copied!!`);
  }

  // const handleGetOtp = () => {
  //   setShowOtp(!showOtp)
  // }

  // const handleShowQr = async() => {
  //   if(qrCodeList.length < 1){
  //     toast.error("No Record Found")
  //   }else{
  //     setShowQrModal(!showQrModal)
  //   }
  //   // await getQrCodeData()
  // }

  // const getQrCodeData = async () => {
  //   let tempQr = []
  //   let res = await GetOtpAction();
  //   let res2 = await GetLdp2Action();
  //   if (res.success) {
  //     setQrData(res.data)
  //     if(res.data.qrCode && (res.data.isCheckout == null || res.data.isCheckout == 0)){
  //       res.data['ldpType'] = "LDP1"
  //       tempQr.push(res.data)
  //     }
     
  //   }
  //   if(res2.success){
  //     setQrData(res.data)
  //     // console.log(res.data.qrCode)
  //     if(res2.data.qrCode  && (res2.data.isCheckout == null || res2.data.isCheckout == 0)){
  //       res2.data['ldpType'] = "LDP2"
  //       tempQr.push(res2.data)
  //     }
    
  //   }
  //   else {
  //     setQrData([])
  //     setQrValue('')
  //   }
  //   setQrCodeList(tempQr)

  // }
  // console.log(qrCodeList)
  return (
    <div className="darktheme">
    <>
      <header className="dashboardheader pt-2 pb-2">
        <Container>
          <Toaster />
          {["xl"].map((expand) => (
            <Navbar key={expand} expand={expand} className="">
              <Container fluid className="pl-0 pr-0">
                <Navbar.Brand href="#">
                  <img src="assets/images/f-logo.png" />
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                />
                <Navbar.Offcanvas
                className="mobile_header"
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    >
                      <img src="assets/images/f-logo.png"  width="180px"/>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className=" flex-grow-1 mx-4 ">
                      <div className="">
                        <div className="total_amount">
                          <div>
                            <ul>
                              <li>
                                <span className="">Stepmint Wallet</span>
                              </li>
                              <li>
                                <span className="">
                                  <img src="assets/images/mntcoin.png" width="20px" />
                                  &nbsp;{userdata.step_balance} MNT
                                </span>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </Nav>
                    <Nav>

                      <div className="profile_box">

                        <div className="">
                          <span className="text-white">{userdata.first_name == null ? "User" : userdata.first_name} {userdata.last_name == null ? "" : userdata.last_name}</span>
                          <br />
                          <span className="text-gradient">
                            <b> <span class="text-sm text-bold text-light">{userdata.bnb_address?.slice(0, 4) + "...." + userdata.bnb_address?.slice(-4)} </span>
                              <CopyToClipboard text={userdata.bnb_address}>
                                <span title="Click to Copy" className="mr-copylink" id="token-buy-button" onClick={copieBtn} style={{ cursor: "pointer", color: 'rgb(157 81 255)' }}>
                                  <i class="fa fa-clone "></i></span></CopyToClipboard></b>
                          </span>
                        </div>
                        <div className="p-img">
                          <a href={`${config.baseUrl}profile`}>
                            {userdata.profile_pic == null || userdata.profile_pic == "" ? <img src={`${config.imageUrl}defaultProfile.png`}  alt="userprofile" /> : <img src={`${config.imageUrl}${userdata.profile_pic}`}  alt="userprofile" />}
                          </a>

                        </div>
                        {/* {loginData.otp && <div >
                          <Button className="btn btn-main2 text-white rounded-2 otp-btn" onClick={handleGetOtp}>{showOtp ? "OTP : " + loginData.otp :"Get OTP"}</Button>
                         
                          </div>} */}
                           {/* <div >
                        {qrCodeList.length > 0 && <Button className="btn btn-main2 text-white rounded-2 " onClick={handleShowQr}>Get Qr Code</Button>}
                         
                          </div> */}
                        <div>
                          <Button className="btn btn-main2 text-white rounded-2" onClick={logout}>Logout</Button>

                        </div>
                       
                      </div>
                    </Nav>
                   
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </Container>
      </header>
      <div className="tabs">
        <Container>
          <Nav variant="pills" className="justify-content-center" activeKey="Stepmint/dashboard">
            <Nav.Item>
              <Nav.Link href={`${config.baseUrl}dashboard`} className={lastparturl=='dashboard'?'active':''}>
                Dashboard
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href={`${config.baseUrl}packageplan`} className={lastparturl=='packageplan'?'active':''}>
                Package Plan
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link href={`${config.baseUrl}useractivity`} className={lastparturl=='useractivity'?'active':''}>
                User Activity
              </Nav.Link>
            </Nav.Item>
           
            <Nav.Item>
              <Nav.Link href={`${config.baseUrl}referral`} className={lastparturl=='referral'?'active':''}>Referral</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href={`${config.baseUrl}matchingbonus`} className={lastparturl=='matchingbonus'?'active':''}>
                Matching Bonus
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link href={`${config.baseUrl}wallethistory`} className={lastparturl=='wallethistory'?'active':lastparturl=='deposit'?'active':lastparturl=='withdraw'?'active':''}>
                Wallet
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="nav-item-with-submenu">
        <Nav.Link
         
          className={lastparturl === 'ldp2' || lastparturl === 'ldp1' ? 'active' : ''}
        >
          Events
        </Nav.Link>
        <div className="submenu">
        <Nav className="submenu-items">
          <Nav.Item className="submenu-item">
              <Link to={`${config.baseUrl}ldp1`} className="submenu-link">
                  LDP 1
              </Link>
          </Nav.Item>
          <Nav.Item className="submenu-item">
              <Link to={`${config.baseUrl}ldp2`} className="submenu-link">
                  LDP 2
              </Link>
          </Nav.Item>
      </Nav>

        </div>
      </Nav.Item>
           
          </Nav>
        </Container>
        <hr className="mt-0 mb-4" />
      </div>
    </>
    {/* {showQrModal && <QRCodeModal showQrModal={showQrModal}  setShowQrModal={setShowQrModal} code={qrvalue} qrCodeList={qrCodeList}/>} */}
    </div>
  );
};
export default Dashboardheader;
