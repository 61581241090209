import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import Dashboardheader from "../directives/dashboardheader";
import { TextField, Box, InputAdornment } from "@mui/material";
import toast from "react-hot-toast";
import "react-confirm-alert/src/react-confirm-alert.css";
import Web3 from "web3";
import Cookies from "js-cookie";
import { confirmAlert } from "react-confirm-alert";
import { MntDepositHistory, UserProfile } from "../Action/user.action";
import CopyToClipboard from "react-copy-to-clipboard";

const Deposit = () => {
  const [mntdepositList, setmntdepositList] = useState([]);
  const loginData = !Cookies.get("loginSuccessStepMrMint")
    ? []
    : JSON.parse(Cookies.get("loginSuccessStepMrMint"));
  const [validatioError, setvalidatioError] = useState({ usdAmount: "" });
  const [userMntBalance, setUserMntBalance] = useState(0);
  const [mntamount, setMntAmount] = useState("");
  const [approve, setApproval] = useState("");
  const [transaction, setTransaction] = useState("");
  const [errormsg, seterrormsg] = useState("");
  const [showTransactionModel, setShowTransactionModel] = useState(false);
  const [startTranaction, setStartTranaction] = useState(false);
  const [userdata, setuserdata] = useState([]);

  useEffect(() => {
    getUserMntbalance();
    getWithdrawListAPI();
    // getStepMintProfile();
  }, []);

  var web3 = new Web3(window.ethereum);
  let MrmintContractCall = new web3.eth.Contract(
    config.mrmintContractAbi,
    config.contractAddressForRecharge
  );

  const getStepMintProfile = async () => {
    let res = await UserProfile();
    if (res.success) {
      setuserdata(res.data);
    } else {
    }
  };

  const getWithdrawListAPI = async () => {
    let res = await MntDepositHistory();
    if (res.success) {
      setmntdepositList(res.data);
    } else {
      seterrormsg(res.msg);
    }
  };

  const inputHandler = (e) => {
    let { name, value, id } = e.target;
    var getDecimalVal = value.toString().indexOf(".");
    var decimalPart = value.toString().substring(getDecimalVal + 1);
    if (decimalPart.length > 2 && getDecimalVal != "-1") {
    } else {
      setMntAmount(value);
    }
  };

  const getUserMntbalance = async () => {
    let balanceOf = await MrmintContractCall.methods
      .balanceOf(loginData?.bnb_address)
      .call();
    balanceOf = (balanceOf / 10 ** 18).toLocaleString("fullwide", {
      useGrouping: false,
    });
    setUserMntBalance(balanceOf);
  };

  const maxToken = async () => {
    setMntAmount(userMntBalance);
  };

  const approval = async () => {
    try {
      let value = mntamount * 10 ** 18;
      value = value.toString();
      let approval = await MrmintContractCall.methods.approve(
        config.depositAddress,
        value
      );
      let encoded_tx = approval.encodeABI();
      let gasPrice = await web3.eth.getGasPrice();
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: config.contractAddressForRecharge,
        from: loginData?.bnb_address,
        data: encoded_tx,
      });
      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: config.contractAddressForRecharge,
        from: loginData?.bnb_address,
        data: encoded_tx,
      });
      if (trx.transactionHash) {
        setApproval("complete");
        return true;
      } else {
        setApproval("failed");
        setTransaction("failed");
        toast.error("Something went wrong");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        return false;
      }
    } catch (error) {
      setApproval("failed");
      setTransaction("failed");
      toast.error(error.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return false;
    }
  };

  const sendTransaction = async () => {
    try {
      let getBnbBal = await web3.eth.getBalance(loginData?.bnb_address);

      if (getBnbBal <= 0) {
        toast.error("Insufficient BNB for transfer");
        setApproval("failed");
        setTransaction("failed");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        return;
      }

      let allowance = await MrmintContractCall.methods.allowance(
        loginData?.bnb_address,
        config.depositAddress
      );
      let value = mntamount - 0.005;
      value = (value * 10 ** 18).toLocaleString("fullwide", {
        useGrouping: false,
      });

      value = value.toString();

      if (allowance < value) {
        toast.error("Allowance is less then amount");
        setTransaction("failed");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        return;
      }
      value = value.toString();
      let userBal = await MrmintContractCall.methods
        .balanceOf(loginData?.bnb_address)
        .call();

      if (parseFloat(userBal) < parseFloat(value)) {
        toast.error("Insuffcient Amount");
        setTransaction("failed");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        return;
      }
      let approval = await MrmintContractCall.methods.transfer(
        config.depositAddress,
        value
      );
      let encoded_tx = approval.encodeABI();
      let gasPrice = await web3.eth.getGasPrice();
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: config.contractAddressForRecharge,
        from: loginData?.bnb_address,
        data: encoded_tx,
      });
      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: config.contractAddressForRecharge,
        from: loginData?.bnb_address,
        data: encoded_tx,
      });
      if (trx.transactionHash) {
        let data = {
          wallet: loginData?.bnb_address,
          amount: parseFloat(mntamount),
          tx_hash: trx.transactionHash,
        };
        toast.success(
          `${trx.transactionHash
            .toString()
            .substring(0, 4)}.....${trx.transactionHash
            .toString()
            .substring(
              trx.transactionHash.length - 4,
              trx.transactionHash.length
            )}`
        );
        setTransaction("complete");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        setTransaction("failed");
        toast.error("Something went wrong");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      toast.error(error.message);
      setTransaction("failed");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  function validate() {
    let balanceError = "";
    if (
      mntamount === "" ||
      mntamount == "0.00" ||
      mntamount == "0.0" ||
      mntamount == "0" ||
      mntamount == "0." ||
      mntamount == "00" ||
      mntamount == "000"
    ) {
      balanceError = "Amount is required.";
    } else if (!isNaN(mntamount) === false) {
      balanceError = "Amount is not valid please enter valid amount.";
    } else if (parseFloat(mntamount) > parseFloat(userMntBalance)) {
      balanceError = "You don't have sufficient balance.";
    }
    if (balanceError) {
      setvalidatioError({
        balanceError,
      });
      return false;
    } else {
      setvalidatioError({
        balanceError,
      });
      return true;
    }
  }

  // const depositNow = async () => {
  //   const isValid = validate();
  //   if (!isValid) {
  //     return;
  //   } else {
  //     if (true) {
  //       const accounts = await window.ethereum.request({
  //         method: "eth_requestAccounts",
  //       });

  //       if (accounts[0].length <= 0) {
  //         toast.error("Please Install metamask");
  //         return;
  //       }

  //       if (accounts[0].toUpperCase() != loginData?.bnb_address.toUpperCase()) {
  //         toast.error("Please connect with registered wallet");
  //         return;
  //       }
  //       confirmAlert({
  //         title: "Confirm to submit",
  //         message: "Are you sure want to deposit?.",
  //         buttons: [
  //           {
  //             label: "Yes",
  //             onClick: async () => {
  //               setStartTranaction(true);
  //               setApproval("pending");
  //               setTransaction("pending");
  //               try {
  //                 let allowance = await MrmintContractCall.methods.allowance(
  //                   loginData?.bnb_address,
  //                   config.depositAddress
  //                 );

  //                 let value = (mntamount * 10 ** 18).toLocaleString(
  //                   "fullwide",
  //                   { useGrouping: false }
  //                 );
  //                 if (allowance < value) {
  //                   let app = await approval();
  //                   if (app) {
  //                     await sendTransaction();
  //                   }
  //                 } else {
  //                   setApproval("complete");

  //                   await sendTransaction();
  //                 }
  //               } catch (error) {}
  //             },
  //           },
  //           {
  //             label: "No",
  //           },
  //         ],
  //       });
  //     }
  //   }
  // };
  const copieBtn = async () => {
    toast.success(`Copied!!`);
  };

  const handleChange = (e) => {
    e.preventDefault();
  };

  const handleClose = () => {
    window.location.reload();
  };

  return (
    <>
      <Dashboardheader />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          {/* ---------- */}
          <div className=" mb-5">
            <Container className="">
              <div className=" card card-view rounded-20 border-0">
                <div className="card-body">
                  <Row className="align-items-center">
                    <Col lg={6} className="">
                      <div className="withdraw_form">
                        <div className="text-center mb-2 pb-1">
                          <h3>Deposit</h3>
                          <h6 className="mb-4 text-grey">
                            Deposit your MNT from metamask account
                          </h6>
                        </div>
                        <div className="sc-kcDeIU cvqsCp">
                          <div className="">
                            <div className="form-group mb-3">
                              <label>Owner Address</label>
                              <input
                                type="text"
                                class="form-control text-white"
                                disabled
                                aria-describedby="basic-addon2"
                                value={config.depositAddress}
                              />
                            </div>
                            <div className="form-group mb-3">
                              <label>Connected Address</label>
                              <input
                                type="text"
                                class="form-control text-white"
                                disabled
                                aria-describedby="basic-addon2"
                                value={loginData?.bnb_address}
                              />
                            </div>

                            <div class="form-group amounttext">
                              <label>Amount</label>
                              <div class="pull-right mntbalance">
                                Balance:{" "}
                                <span id="web13Balance">
                                  {parseFloat(userMntBalance).toFixed(2)} MNT
                                </span>
                              </div>
                              <div className="mxbtn">
                                <div class="form-group position-relative mt-1">
                                  <input
                                    onKeyPress={(event) => {
                                      if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onPaste={handleChange}
                                    onChange={inputHandler}
                                    type="text"
                                    class="form-control text-white w-100 rounded-2"
                                    decimals="2"
                                    value={mntamount}
                                    aria-label="Enter Amount (MNT)"
                                    aria-describedby="basic-addon2"
                                    placeholder="Enter Amount"
                                    id="fixed2"
                                  />
                                  &emsp;
                                  <div class=" input-group-append">
                                    <button
                                      onClick={maxToken}
                                      className="pull-right text-white px-4 btn btn-main2 maxamount2 rounded-2"
                                      type="button"
                                    >
                                      MAX
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <span
                                className="validationErr"
                                style={{ color: "red" }}
                              >
                                {validatioError.balanceError}
                              </span>
                            </div>
                          </div>
                        </div>

                        {startTranaction ? (
                          <>
                            <div className="transactionDialog pt-2 pb-2 btn-rounded mt-3 w-100">
                              <table width="100%">
                                <tr>
                                  <td>
                                    <span>Approval Status : </span>
                                  </td>
                                  <td>
                                    {approve == "pending" ? (
                                      <button className="btn btn-sm pt-0 pb-0  btn-warning">
                                        Pending
                                      </button>
                                    ) : approve == "failed" ? (
                                      <button className="btn btn-sm pt-0 pb-0  btn-danger">
                                        Failed
                                      </button>
                                    ) : approve == "complete" ? (
                                      <button className="btn btn-sm pt-0 pb-0 btn-success">
                                        Complete
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span>Transaction Status :</span>
                                  </td>
                                  {/* <td><span>{transaction}</span></td> */}
                                  <td>
                                    {transaction == "pending" ? (
                                      <button className="btn btn-sm pt-0 pb-0  btn-warning">
                                        Pending
                                      </button>
                                    ) : transaction == "failed" ? (
                                      <button className="btn btn-sm pt-0 pb-0  btn-danger">
                                        Failed
                                      </button>
                                    ) : transaction == "complete" ? (
                                      <button className="btn btn-sm pt-0 pb-0 btn-success">
                                        Complete
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </>
                        ) : (
                          <Button
                            className="btn btn-main2 btn-sm mt-3 w-100"
                            id="token-buy-button"
                            // onClick={depositNow}
                          >
                            Deposit Now
                          </Button>
                        )}
                      </div>
                    </Col>

                    <Col lg={6} className="mt-3">
                      <div>
                        <img src="assets/images/deposit.png" width="100%" />
                      </div>
                    </Col>
                  </Row>
                  <Modal
                    show={showTransactionModel}
                    className="transaction_modal"
                    onHide={handleClose}
                    backdrop="static"
                  >
                    <Modal.Header className="text-center" closeButton>
                      <Modal.Title className="text-white">
                        <h5 className="mb-0">Transaction</h5>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-white">
                      <Container>
                        <Row className="mb-3">
                          <Col lg={10}>
                            {startTranaction ? (
                              <>
                                <div className="sc-CtfFt bxUreM mt-3 w-100">
                                  <span>
                                    Approval <br />
                                    {approve}{" "}
                                  </span>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col>
                            {" "}
                            {startTranaction ? (
                              <>
                                <div className="sc-CtfFt bxUreM mt-3 w-100">
                                  <span>
                                    Transaction <br />
                                    {transaction}{" "}
                                  </span>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Container>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </Container>
          </div>
          {/* ---------- */}
          <div className="mb-3">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <h1 className="text-white">Deposit History</h1>
                </Col>
              </Row>
            </Container>
          </div>

          {/* ---------- */}

          <div className="latest_transaction mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <div className="card card-view2">
                    <div className="card-body p-4">
                      <div className="mt-2">
                        <div className="table-responsive mb-2">
                          <table width="100%">
                            <thead>
                              {mntdepositList == [] ? (
                                ""
                              ) : (
                                <tr className="border-0">
                                  <th>Date</th>
                                  <th>Amount (MNT)</th>
                                  <th>Hash</th>
                                  <th>Wallet Address</th>
                                </tr>
                              )}{" "}
                            </thead>

                            <tbody>
                              {mntdepositList.length == 0 ? (
                                <tr className="tr_list">
                                  {/* <td></td> */}
                                  {/* <td></td> */}
                                  <td colspan="4">No Data Available</td>
                                  {/* <td></td> */}
                                </tr>
                              ) : mntdepositList == [] ? (
                                ""
                              ) : (
                                mntdepositList?.map((item, index) => (
                                  <tr class="tr_list">
                                    <td>{item.timestamp}</td>
                                    <td>{item.amount}MNT</td>
                                    <td>
                                      {" "}
                                      <span className="text-gradient">
                                        <b>
                                          {" "}
                                          <span class="text-sm text-bold text-light">
                                            {item.tx_hash?.slice(0, 4) +
                                              "...." +
                                              item.tx_hash?.slice(-4)}{" "}
                                          </span>
                                          <CopyToClipboard text={item.tx_hash}>
                                            <span
                                              title="Click to Copy"
                                              className="mr-copylink"
                                              id="token-buy-button"
                                              onClick={copieBtn}
                                              style={{
                                                cursor: "pointer",
                                                color: "rgb(157 81 255)",
                                              }}
                                            >
                                              <i class="fa fa-clone "></i>
                                            </span>
                                          </CopyToClipboard>
                                        </b>
                                      </span>
                                    </td>
                                    <td>
                                      <span className="text-gradient">
                                        <b>
                                          {" "}
                                          <span class="text-sm text-bold text-light">
                                            {item.wallet_address?.slice(0, 4) +
                                              "...." +
                                              item.wallet_address?.slice(
                                                -4
                                              )}{" "}
                                          </span>
                                          <CopyToClipboard
                                            text={item.wallet_address}
                                          >
                                            <span
                                              title="Click to Copy"
                                              className="mr-copylink"
                                              id="token-buy-button"
                                              onClick={copieBtn}
                                              style={{
                                                cursor: "pointer",
                                                color: "rgb(157 81 255)",
                                              }}
                                            >
                                              <i class="fa fa-clone "></i>
                                            </span>
                                          </CopyToClipboard>
                                        </b>
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="text-right">
                          {/* <Button variant="custom" className="text-white px-5">
                            See all
                          </Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Deposit;
