import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Accordion,
  Form
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import { TextField, FormGroup, FormControlLabel, Checkbox } from "@mui/material";

const Success = () => {
  return (
    <>
      <Header />

      <section className="position-relative overflow-hidden signup">
      <div className="shape_bg">
          <img src="assets/images/shape.svg" className="shape" />
          <img src="assets/images/shape1.svg" className="shape1" />
          <img src="assets/images/shape2.svg" className="shape2" />
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={5} className="">
              <Zoom>
              <div className="signup_form">
                <Container className="">
                  <Row>
                    <Col lg={12} className="text-center mb-4">
                      <div>
                        <div className="mb-3">
                          <img src=" assets/images/icon/circle_check.png" width="65px"/>
                        </div>
                        <h3 className="text-white">Success !</h3>
                        <small className="text-light-grey">A email has been send to your email@domain.com. Please check for an email from company and click on the included link to reset your password.</small>
                      </div>
                    </Col>
                    <Col lg={12} className="mb-3 text-center">
                          <div>
                            <button
                              type="button"
                              className="text-white px-5  btn btn-main2 w-100"
                            >
                              Back to home 
                            </button>
                            </div>
                    </Col>
                    
                    
                  </Row>
                </Container>
              </div>
              </Zoom>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};
export default Success;
