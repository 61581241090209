import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { Container, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import Dashboardheader from "../directives/dashboardheader";

const Buyhistory = () => {
  return (
    <>
      <Dashboardheader />
      <div className="shape_bg">
          <img src="assets/images/shape.svg" className="shape" />
          <img src="assets/images/shape1.svg" className="shape1" />
          <img src="assets/images/shape2.svg" className="shape2" />
          {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
        </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          <div className="mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <h1 className="text-gradient">NFT Purchase History</h1>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ---------- */}
          <div className=" mb-5">
            <Container className="">
                <Row>
                    <Col lg={12}>
                        <div className="staking_banner card-view">
                          <img src="assets/images/star_left.png" className="star_left"/>
                          <img src="assets/images/star_right.png" className="star_right"/>
                          <img src="assets/images/coin.png" className="coin_right"/>
                          <img src="assets/images/coin2.png" className="coin_left"/>
                          <Row>
                            <Col lg={2}>
                            </Col>
                            <Col lg={8}>
                               <img src="assets/images/nft_purchase.png" className="stake_img p-4"/>
                            </Col>
                            <Col lg={2}>
                            </Col>
                          </Row>
                         

                        </div>
                    </Col>
                </Row>

            </Container>

          </div>
            {/* ---------- */}
            <div className="mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <h1 className="text-gradient">Purchase History </h1>
                </Col>
              </Row>
            </Container>
          </div>
         {/* ---------- */}

         <div className="latest_transaction mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <div className="">
                    <div className="table-responsive">
                      <table width="100%" className="text-center">
                        <thead>
                          <tr className="table-head px-4">
                            <th>#</th>
                            <th>NFT Name</th>
                            <th>USD Amount</th>
                            <th>Period</th>
                            <th>Complete Days</th>
                            <th>Purchase Date</th>
                          </tr>
                        </thead>
                        <tbody className="card-view1 p-4 d-block">
                          <tr>
                            <td>01</td>
                            <td><img src="assets/images/image1.png"/>&nbsp;Starwallets</td>
                            <td>$100</td>
                            <td>100 Day’s</td>
                            <td>70 Day’s</td>
                            <td>2023-04-06 </td>
                          </tr>
                          <tr>
                            <td>02</td>
                            <td><img src="assets/images/image1.png"/>&nbsp;Starwallets</td>
                            <td>$100</td>
                            <td>100 Day’s</td>
                            <td>70 Day’s</td>
                            <td>2023-04-06 </td>
                          </tr>
                          <tr>
                            <td>03</td>
                            <td><img src="assets/images/image2.png"/>&nbsp;Starwallets</td>
                            <td>$100</td>
                            <td>100 Day’s</td>
                            <td>70 Day’s</td>
                            <td>2023-04-06 </td>
                          </tr>
                          <tr>
                            <td>04</td>
                            <td><img src="assets/images/image2.png"/>&nbsp;Starwallets</td>
                            <td>$100</td>
                            <td>100 Day’s</td>
                            <td>70 Day’s</td>
                            <td>2023-04-06 </td>
                          </tr>
                          <tr>
                            <td>05</td>
                            <td><img src="assets/images/image2.png"/>&nbsp;Starwallets</td>
                            <td>$100</td>
                            <td>100 Day’s</td>
                            <td>70 Day’s</td>
                            <td>2023-04-06 </td>
                          </tr>
                          <tr>
                            <td>06</td>
                            <td><img src="assets/images/image1.png"/>&nbsp;Starwallets</td>
                            <td>$100</td>
                            <td>100 Day’s</td>
                            <td>70 Day’s</td>
                            <td>2023-04-06 </td>
                          </tr>
                          <tr>
                            <td>07</td>
                            <td><img src="assets/images/image2.png"/>&nbsp;Starwallets</td>
                            <td>$100</td>
                            <td>100 Day’s</td>
                            <td>70 Day’s</td>
                            <td>2023-04-06 </td>
                          </tr>
                        
                        </tbody>
                      </table>
                    </div>

                    <div className="text-right mt-4">
                      <Button variant="custom" className="text-white px-5">
                        See all
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Buyhistory;
