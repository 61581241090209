import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { Container, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import Dashboardheader from "../directives/dashboardheader";

const Earninghistory = () => {
  return (
    <>
      <Dashboardheader />
      <div className="shape_bg">
          <img src="assets/images/shape.svg" className="shape" />
          <img src="assets/images/shape1.svg" className="shape1" />
          <img src="assets/images/shape2.svg" className="shape2" />
          {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
        </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          <div className="mb-4">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <h1 className="text-gradient">Earning History </h1>
                </Col>
              </Row>
            </Container>
          </div>
          <div className=" mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <div className="card card-view2">
                    <div className="card-body p-4">
                      <Row className="align-items-center">
                        <Col lg={12} className="">
                          <p className="mb-0">Total Referral Earning: </p>
                          <div className="text-center pb-3 mt-3">
                            <h3>
                              <b>$0.00 ~ 0.00 BNB</b>
                            </h3>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ---------- */}
          <div className=" mb-5">
            <Container className="">
                <div className="card-view card-body p-4">
              <Row className="mb-4">
                <Col lg={12} className="">
                  <div className="refferal_address ">
                    <Form.Group
                      controlId="formBasicEmail"
                      className="text-left"
                    >
                      <div className="d-flex align-items-center">
                        <Form.Control
                          type="text"
                          placeholder=" https://www.starwallets.io?sponsor=VIMALMEENA"
                          className="mr-3 custom_field"
                        />
                        <Button variant="btn btn-main2">Copy</Button>
                      </div>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row >
                <Col lg={6}>
                    <p className="mb-0">Start: 17th July 2023</p>
                </Col>
                <Col lg={6} className="text-right">
                    <p className="mb-0">End: 17th July 2023</p>
                </Col>
                <Col lg={12}>
                    <hr className="mt-1 mb-1"/>
                    <p className="mb-0">You will continue to receive rewards from the pool for a few seconds ago.</p>
                </Col>
              </Row>
              </div>
            </Container>
          </div>
          {/* ---------- */}
          <div className="mb-4">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <h3 className="text-gradient">
                    <b>
                  Referral Earning 
                  </b>
                  </h3>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ---------- */}

          <div className="latest_transaction mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <div className="">
                    <div className="table-responsive">
                      <table width="100%" className="text-center">
                        {/* <colgroup>
                        <col class="col-md-4">
                        <col class="col-md-7">
                        </colgroup> */}
                        <thead>
                          <tr className="table-head px-4 right_border_line right_border_line">
                            <th>#</th>
                            <th>Token</th>
                            <th>Referral From</th>
                            <th>Referral Percent</th>
                            <th>Referral Level</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody className="card-view1 p-4 d-block">
                          <tr>
                            <td>01</td>
                            <td>#100</td>
                            <td>Ezxd0215510074</td>
                            <td>5.77%</td>
                            <td>User Id 00012</td>
                            <td>2023-04-06  </td>
                          </tr>
                          <tr>
                            <td>01</td>
                            <td>#100</td>
                            <td>Ezxd0215510074</td>
                            <td>5.77%</td>
                            <td>User Id 00012</td>
                            <td>2023-04-06  </td>
                          </tr>
                          <tr>
                            <td>01</td>
                            <td>#100</td>
                            <td>Ezxd0215510074</td>
                            <td>5.77%</td>
                            <td>User Id 00012</td>
                            <td>2023-04-06  </td>
                          </tr>
                          <tr>
                            <td>01</td>
                            <td>#100</td>
                            <td>Ezxd0215510074</td>
                            <td>5.77%</td>
                            <td>User Id 00012</td>
                            <td>2023-04-06  </td>
                          </tr>
                          <tr>
                            <td>01</td>
                            <td>#100</td>
                            <td>Ezxd0215510074</td>
                            <td>5.77%</td>
                            <td>User Id 00012</td>
                            <td>2023-04-06  </td>
                          </tr>
                          <tr>
                            <td>01</td>
                            <td>#100</td>
                            <td>Ezxd0215510074</td>
                            <td>5.77%</td>
                            <td>User Id 00012</td>
                            <td>2023-04-06  </td>
                          </tr>
                        
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
           {/* ---------- */}
           <div className="mb-4">
            <Container className="">
              <Row className="mb-4">
                <Col lg={12}>
                  <h3 className="text-gradient"><b>
                  All Transactions
                  </b>
                  </h3>
                </Col>
              </Row>
              
                
              <div className="card-view card-body p-4">
              <Row className="justify-content-center">
                <Col lg={12}>
                    <Row className="justify-content-center text-center mt-3">

                            
                                <Col lg={4} className="mb-4">
                                    <Button variant="main2" className=" rounded font-inherit">Total Referral Earning: $0.00 ~ 0.00 BNB</Button>

                                </Col>
                                <Col lg={4} className="mb-4">
                                    <Button variant="main2" className=" rounded font-inherit">Total Pairing Earning: $0.00 ~ 0.00 BNB</Button>

                                </Col>
                                <Col lg={4} className="mb-4">
                                    <Button variant="main2" className=" rounded font-inherit">Total Royalty Earning: $0.00 ~ 0.00 BNB</Button>

                                </Col>
                                <Col lg={4} className="mb-4">
                                    <Button variant="main2" className=" rounded font-inherit">Total Staking Royalty Earning: $0.00 ~ 0.00 BNB</Button>
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <Button variant="main2" className=" rounded font-inherit">Total Earning: $0.00 ~ 0.00 BNB</Button>
                                </Col>

                        

                
                    </Row>
                    </Col>
              </Row>
              </div>
            
            </Container>
          </div>
          {/* ---------- */}

          <div className="all_transaction mt-5 mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <div className="">
                    <div className="table-responsive">
                      <table width="100%" className="text-center">
                        {/* <colgroup>
                        <col class="col-md-4">
                        <col class="col-md-7">
                        </colgroup> */}
                        <thead>
                          <tr className="table-head px-4 right_border_line right_border_line">
                            <th>#</th>
                            <th>Transaction Type</th>
                            <th>USD Amount</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody className="card-view1 p-4 d-block">
                          <tr>
                            <td>01</td>
                            <td>Staking</td>
                            <td>$100.00</td>
                            <td>2023-04-06 </td>
                          </tr>
                          <tr>
                            <td>02</td>
                            <td>NFT Purchase</td>
                            <td>$100.00</td>
                            <td>2023-04-06 </td>
                          </tr>
                          <tr>
                            <td>03</td>
                            <td>Staking</td>
                            <td>$100.00</td>
                            <td>2023-04-06 </td>
                          </tr>
                          <tr>
                            <td>04</td>
                            <td>NFT Purchase</td>
                            <td>$100.00</td>
                            <td>2023-04-06 </td>
                          </tr>
                          <tr>
                            <td>01</td>
                            <td>Staking</td>
                            <td>$100.00</td>
                            <td>2023-04-06 </td>
                          </tr>
                          <tr>
                            <td>01</td>
                            <td>NFT Purchase</td>
                            <td>$100.00</td>
                            <td>2023-04-06 </td>
                          </tr>
                        
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ---------- */}
          <div className="mb-4">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <h3 className="text-gradient">
                    <b>
                  Monthly Income
                  </b>
                  </h3>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ---------- */}

          <div className="monthlyIncome mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <div className="">
                    <div className="table-responsive">
                      <table width="100%" className="text-center">
                        {/* <colgroup>
                        <col class="col-md-4">
                        <col class="col-md-7">
                        </colgroup> */}
                        <thead>
                          <tr className="table-head px-4 right_border_line">
                            <th>#</th>
                            <th>Month</th>
                            <th>Pairing Bonus</th>
                            <th>Referral Bonus</th>
                            <th>Royalty Bonus</th>
                            <th>Staking Royalty Bonus</th>
                            <th>Total Bonus</th>
                          </tr>
                        </thead>
                        <tbody className="card-view1 p-4 d-block">
                          <tr>
                            <td>01</td>
                            <td>May-2023</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$500.00 </td>
                          </tr>
                          <tr>
                            <td>01</td>
                            <td>May-2023</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$500.00 </td>
                          </tr>
                          <tr>
                            <td>01</td>
                            <td>May-2023</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$500.00 </td>
                          </tr>
                          <tr>
                            <td>01</td>
                            <td>May-2023</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$500.00 </td>
                          </tr>
                          <tr>
                            <td>01</td>
                            <td>May-2023</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$100.00</td>
                            <td>$500.00 </td>
                          </tr>
                      
                        </tbody>
                      </table>
                    </div>

                   
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Earninghistory;
