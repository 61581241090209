import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Accordion,
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation, Pagination } from "swiper/modules";

const Faq = () => {
  return (
    <>
      <Header />

      <section className="position-relative overflow-hidden">
      <div className="leftshadow">
          <img src="assets/images/blueleft.png"/>

        </div>
        <Container>
          <Col lg={12}>
            <div>
              <Slide right>
              <h1 className="text-gradient">
                Frequently Asked Questions on the StarWallets NFT Staking Program
              </h1>
              </Slide>
             <Slide left>
              <div>
                <small className="text-light-grey">2023-07-05 13:31</small>
              </div>
              </Slide>
              <Fade left>
              <p className="text-light-grey mt-4">
                Users who own NFTs within the Bored Apes ecosystem can
                participate in the StarWallets NFT Staking Program on StarWallets NFT.
                You can stake your Bored Ape Yacht Club (BAYC) and Mutant Ape
                Yacht Club (MAYC) NFTs in various pools to earn daily rewards in
                ApeCoin (APE). Please note that Bored Ape Kennel Club (BAKC)
                staking will be available in the near future.
              </p>
              </Fade>
            </div>
          </Col>
          <Col lg={12} className="mt-5">
            <div className="">
              <Slide left>
              <h2 className=" text-gradient">
                {" "}
                Why should I stake my StarWallets NFTs in the StarWallets NFT Staking Program?
              </h2>
              </Slide>
              <Fade left>
              <p className="text-light-grey">
                Here are some benefits of staking your StarWallets NFTs in the Binance
                NFT StarWallets NFT Staking Program:
              </p>
              <ul>
                <li className="mb-3">
                  <small className="text-light-grey">
                    User-friendly, one-click-to-stake experience.
                  </small>
                </li>

                <li className="mb-3">
                  <small className="text-light-grey">
                    Staking rewards are distributed to your account daily.
                  </small>
                </li>

                <li className="mb-3">
                  <small className="text-light-grey">
                    Flexible term staking option.
                  </small>
                </li>

                <li className="mb-3">
                  <small className="text-light-grey">
                    You don’t necessarily need to hold APE.
                  </small>
                </li>

                <li className="mb-3">
                  <small className="text-light-grey">
                    Zero gas fees to stake and unstake on StarWallets NFT.
                  </small>
                </li>

                <li className="mb-3">
                  <small className="text-light-grey">
                    Competitive APR and daily rewards.
                  </small>
                </li>
              </ul>
              </Fade>
            </div>
          </Col>
          <Col lg={12} className="mt-5">
            <Zoom>
            <div className="questions">
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header> <h5 className="text-gradient mb-0">1. How to stake my StarWallets NFTs to earn rewards?</h5></Accordion.Header>
                  <Accordion.Body>
                    <p className="text-light-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header> <h5 className="text-gradient  mb-0">2. How are the daily staking rewards calculated and when will I start earning?</h5></Accordion.Header>
                  <Accordion.Body>
                    <p className="text-light-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header> <h5 className="text-gradient mb-0">3. Why is the interest calculated based on the APR different from the actual interest I received?</h5></Accordion.Header>
                  <Accordion.Body>
                    <p className="text-light-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header> <h5 className="text-gradient mb-0">4. What are the staking and unstaking rules?</h5></Accordion.Header>
                  <Accordion.Body>
                    <p className="text-light-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header> <h5 className="text-gradient mb-0">5. How do I unstake my NFT?</h5></Accordion.Header>
                  <Accordion.Body>
                    <p className="text-light-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header> <h5 className="text-gradient mb-0">6. How to view the purchased NFTs?</h5></Accordion.Header>
                  <Accordion.Body>
                    <p className="text-light-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header> <h5 className="text-gradient mb-0">7. Why StarWallets </h5></Accordion.Header>
                  <Accordion.Body>
                    <p className="text-light-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            </Zoom>
          </Col>
        </Container>
      </section>

      <Footer />
    </>
  );
};
export default Faq;
