import React, { useEffect, useState } from "react";
import config from "../config/config";
import { Link, useParams } from "react-router-dom";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Web3 from "web3";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Accordion,
  Modal,
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import { TextField, Box } from "@mui/material";
import "../component/nftdetail.css";
import {
  DisplaySelectedSubscriptionPlanId,
  UserProfile,
  purchasesubscription,
} from "../Action/user.action";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Dashboardheader from "../directives/dashboardheader";
import toast, { Toaster } from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";
import { Vortex } from "react-loader-spinner";

const BuyNowMobile = () => {
  const [plan, setplan] = useState({});
  let { id } = useParams();

  const [userdata, setuserdata] = useState([]);
  const [userdatahistory, setuserdatahistory] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalLoad, setShowModalLoad] = useState(false);
  const [currentBNBBalance, setcurrentBNBBalance] = useState("0.000000");
  const [currentMNTBalance, setcurrentMNTBalance] = useState("0.000000");
  const [transactionError, settransactionError] = useState("");
  const [buyTransactionHash, setbuyTransactionHash] = useState("");
  const [nftId, setNFTId] = useState();
  const [connectWalletAddress, setConnectWalletAddress] = useState("");
  const [process, setprocess] = useState("");
  const [MintingTimer, setMintingTimer] = useState("");
  const [planPrice, setPlanPrice] = useState(0);
  const [nftMinted, setNftMinted] = useState(
    localStorage.getItem("itemType") == id
  );
  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
    );

  useEffect(() => {
    getsubscriptionid();







    // setTimeout(() => {
    //   setShowModal(true);
    //   let timer = 120;
    //   setInterval(() => {
    //     timer -= 1;
    //     setMintingTimer(timer);
    //     if (timer<=0){
    //       window.location.reload();
    //     }
    //   }, 1500);
    // }, 1000);
    setTimeout(() => {
      if (loginData.id) {
        getStepMintProfile();
        getBNBBalance();
      }
    }, 500);
  }, []);

  const getBNBBalance = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setConnectWalletAddress(accounts[0]);
      let web3 = new Web3(window.ethereum);
      let getBalace = (await web3.eth.getBalance(accounts[0])) / 10 ** 18;
      let currentBal = parseFloat(getBalace).toFixed(6);
      setcurrentBNBBalance(currentBal);
    }
  };

  const approve = async (
    abi,
    contractAddress,
    amount,
    toAddress,
    from_address
  ) => {
    setprocess("Your approval is processing");

    let web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(abi, contractAddress);
    let allowance = await contract.methods
      .allowance(from_address, toAddress)
      .call();

    allowance = parseFloat((parseInt(allowance) / 10 ** 18).toFixed(6));
    console.log({ allowance, amount });
    if (allowance >= amount) {
      return true;
    }
    amount = web3.utils.toWei(amount.toString(), "ether");
    console.log({ amount });
    try {
      let approve = await contract.methods.approve(toAddress, amount);
      let encoded_tx = approve.encodeABI();
      let gasPrice = await web3.eth.getGasPrice();
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: contractAddress,
        from: from_address,
        data: encoded_tx,
      });
      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: contractAddress,
        from: from_address,
        data: encoded_tx,
      });
      if (trx.transactionHash) {
        console.log("trx.transactionHash", trx.transactionHash);
        return true;
      } else {
        toast.error("User Reject Approval");
        setShowModal(false);
        return false;
      }
    } catch (err) {
      if (err.code == 4001) {
        toast.error("User Reject Approval");
      } else if (err.code == -32603) {
        toast.error("Insufficient Allowance");
      } else {
        toast.error(err.message);
      }
      setShowModal(false);
      return false;
    }
  };

  const redirect = () => {
    setShowModalLoad(false);
    window.location.reload();
  };

  const redirection = () => {
    window.location.href = `${config.baseUrl}dashboard`;
  };



  const purchaseplanfunc = async (id) => {
    try {
      setShowModal(true);
      setprocess("Your Transaction is processing");
      if (!loginData.id) {
        setShowModal(false);
        toast.error(
          "You need to connect with your Meta mask Wallet before purchase"
        );
        return;
      }

      if (window.ethereum) {
        let web3 = new Web3(window.ethereum);

        let chainId = await web3.currentProvider.chainId;
        if (!chainId) {
          chainId = web3.currentProvider.getChainId();
        }
        if (chainId != config.chainId && web3.utils.toHex(parseInt(chainId)) != config.chainId) {
          toast.error(`Please select BNB smartchain!!`);
          setShowModal(false);
          return false;
        }
        let MrmintContractCall = new web3.eth.Contract(
          config.mrmintContractAbi,
          config.contractAddressForRecharge
        );

        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        let from_address = accounts[0];
        if (loginData.bnb_address.toLowerCase() != from_address.toLowerCase()) {
          toast.error(`Please connect with registered wallet`);
          setShowModal(false);
          return false;
        }
        let balanceOf = await MrmintContractCall.methods
          .balanceOf(accounts[0])
          .call();
        let contractWallet = new web3.eth.Contract(
          config.purchaseContractAddressABI,
          config.purchaseContractAddress
        );
        let getpurchaseStepByToken = await contractWallet.methods
          .getNftPriceInMNT(nftId)
          .call();
        let updateAmountToken = parseFloat(
          (parseInt(getpurchaseStepByToken) / 10 ** 18 + 5).toFixed(6)
        );
        setPlanPrice(updateAmountToken);
        balanceOf = parseFloat((parseInt(balanceOf) / 10 ** 18).toFixed(6));
        console.log({ updateAmountToken, balanceOf });
        if (!loginData.id) {
          setShowModal(false);
          toast.error(
            "You need to connect with your Meta mask Wallet before purchase"
          );
          return;
        }
        if (updateAmountToken > balanceOf) {
          toast.error(`Insufficient Balance`);
          setShowModal(false);
          return;
        }
        let approval = await approve(
          config.mrmintContractAbi,
          config.contractAddressForRecharge,
          updateAmountToken,
          config.purchaseContractAddress,
          from_address
        );

        if (approval) {
          try {
            setprocess("Your Transaction is processing");
            let purchaseStepByToken = await contractWallet.methods.purchaseNFT(
              nftId
            );

            let encoded_tx = purchaseStepByToken.encodeABI();
            let gasPrice = await web3.eth.getGasPrice();
            let gasLimit = await web3.eth.estimateGas({
              gasPrice: web3.utils.toHex(gasPrice),
              to: config.purchaseContractAddress,
              from: from_address,
              data: encoded_tx,
            });
            gasPrice = parseInt(gasPrice) + 5 * 10 ** 9;

            console.log({ gasPrice, gasLimit });

            let trx = await web3.eth.sendTransaction({
              gasPrice: web3.utils.toHex(gasPrice),
              gas: web3.utils.toHex(gasLimit),
              to: config.purchaseContractAddress,
              from: from_address,
              data: encoded_tx,
            });
            console.log("trxxxxx", trx);
            if (trx) {
              setbuyTransactionHash(trx.transactionHash);
              localStorage.setItem("itemType", nftId);
              toast.success("Transaction has been created successfully");
              setTimeout(() => {
                setShowModal(true);
                let timer = 120;
                setInterval(() => {
                  timer -= 1;
                  setMintingTimer(timer);
                  if (timer <= 0) {
                    window.location.reload();
                  }
                }, 1500);
              }, 1000);
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1500);
            }
          } catch (err) {
            setShowModal(false);
            if (err.code == -32603) {
              toast.error("Insufficent Allowance : " + err.message);
            }
            if (err.message.toString().split("insufficient funds")[1]) {
              settransactionError("Transaction reverted : " + err.message);
              toast.error("Transaction reverted : " + err.message);
            } else {
              if (err.toString().split("execution reverted:")[1]) {
                toast.error(
                  "Transaction reverted : " +
                  err
                    .toString()
                    .split("execution reverted:")[1]
                    .toString()
                    .split("{")[0]
                );
                settransactionError(
                  "Transaction reverted : " +
                  err
                    .toString()
                    .split("execution reverted:")[1]
                    .toString()
                    .split("{")[0]
                );
              } else {
                settransactionError(err.message);
                toast.error(err.message);
              }
            }
            setShowModal(false);
            return false;
          }
        }
        // toast.error("Something went wrong! Please try again!!");
        setShowModal(false);
      } else {
        toast.error("Please use Dapp browser!!");
      }
    } catch (error) {
      console.log(error);
      switch (error.code) {
        case 4001:
          toast.error("User Rejected");
          break;
        case -32002:
          toast.error(
            "Request Already Processing, Please check your Meta Mask wallet"
          );
          break;
        case "ERR_NETWORK":
          toast.error("We're experiencing technical difficulties.");
          break;
        default:
          toast.error(error.message);
          break;
      }
      setShowModal(false);
    }
  };

  const getStepMintProfile = async () => {
    let res = await UserProfile();
    if (res.success) {
      setuserdata(res.data);
    } else {
      setuserdata([]);
    }
  };

  const copieBtn = async () => {
    toast.success(`Copied!!`);
  };

  const getsubscriptionid = async () => {
    if (id == undefined) {
      id = 1;
    }
    let res = await DisplaySelectedSubscriptionPlanId(id);
    setNFTId(id);
    if (res.success) {
      setplan(res.data.nftdetail[0]);
      setPlanPrice(
        res.data.nftdetail[0].price / res.data.nftdetail[0].live_price
      );
      setuserdatahistory(res.data.history);
    } else {
      setplan(res.msg);
    }
  };

  return (
    <>
      {/* {loginData.id ? <Dashboardheader /> : <Header />} */}
      <Toaster />
      <section className="position-relative overflow-hidden nftdetail">
        <div className="leftshadow">
          <img src="assets/images/blueleft.png" />
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <Fade right>
                  <h1 className="text-white">NFTs Details</h1>
                </Fade>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card card-view border-0 p-4 position-relative overflow-hidden">
                <div>
                  <img
                    src="assets/images/logo-shap3.png"
                    className="botton-left"
                  />
                </div>
                <Row>
                  <Col lg={5}>
                    <div className="card nftplan">
                      <div className="card-body p-0">
                        <div className=" p-3 pb-3">
                          <div>
                            <table width="100%">
                              <tr>
                                <th className="">
                                  <h5 className="mb-0 text-white">
                                    {plan.name}
                                  </h5>
                                </th>
                                <td>
                                  <div>
                                    {!nftMinted ? (
                                      <Button
                                        variant="main2"
                                        onClick={() => purchaseplanfunc(plan)}
                                        className="rounded-1 btn-sm mh-auto pt-2 pb-2 px-4"
                                      >
                                        Buy Now
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="main2"
                                        className="rounded-1 btn-sm mh-auto pt-2 pb-2 px-4"
                                        // disabled={true}
                                        onClick={redirection}
                                      >
                                        Subscribed
                                      </Button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                              <tr className="mt-2">
                                <th className="">
                                  {" "}
                                  <span className="mb-0">
                                    ${plan.price}~{planPrice.toFixed(2)}
                                    &nbsp;MNT
                                  </span>
                                </th>
                                {/* <td
                                  className="text-light-grey"
                                  style={{ wordBreak: "break-all" }}
                                >
                                  Remaining NFT {plan.remaining}
                                </td> */}
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div className="card-view text-center p-2 rounded-30">
                          <img
                            src={`${config.ipfsurl + plan.image}`}
                            alt={plan.image}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={7} className="">
                    <div className="package_detail">
                      <ul className="">
                        <li>
                          <div className="d-flex justify-content-between">
                            <div>Type of NFT</div>
                            <p className="mb-0">
                              {plan.editionTypeId == 1
                                ? "Basic Nft"
                                : plan.editionTypeId
                                  ? "Limited Edition"
                                  : "Unkonwn Status"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            <div>
                              Subscription Period{" "}
                            </div>
                            <p className="mb-0">1 Year</p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            <div>
                              Capacity <small>(Steps)</small>
                            </div>
                            <p className="mb-0">{parseInt(plan.capacity)}</p>
                          </div>
                        </li>

                        <li>
                          <div className="d-flex justify-content-between">
                            <div>Per Day Reward</div>
                            <p className="mb-0">
                              upto &nbsp;{plan.rewardPercentPhase1}%
                            </p>
                          </div>
                        </li>
                        {/* <li>
                          <div className="d-flex justify-content-between">
                            <div>Per Month Reward</div>
                            <p className="mb-0">
                              upto {(31 * plan.rewardPercentPhase1).toFixed(2)}%
                            </p>
                          </div>
                        </li> */}

                        {/* <li>
                          <div className="d-flex justify-content-between">
                            <div>Total Supply</div>
                            <p className="mb-0">
                              {plan.quantity}&nbsp;<small>NFTs</small>
                            </p>
                          </div>
                        </li> */}
                        <li>
                          <div className="d-flex justify-content-between">
                            <div>Sold</div>
                            <p className="mb-0">
                              {plan.sold}&nbsp;<small>NFT</small>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  {/* <div className="connect_line">
                    <img src="assets/images/curl_line1.svg" className="curlline line1" />
                    <img src="assets/images/curl_line2.svg" className="curlline line2" />
                    <img src="assets/images/curl_line3.svg" className="curlline line3" />
                    <img src="assets/images/curl_line4.svg" className="curlline line4" />
                    <img src="assets/images/curl_line5.svg" className="curlline line5" />
                    <img src="assets/images/curl_line6.svg" className="curlline line6" />

                  </div> */}
                </Row>
              </div>
            </Col>

            {/* <Col lg={12} className="mt-5">
              <div className="mb-4">
                <h3 className="text-white"><b>Recent Purchase</b></h3>
              </div>
              <Col lg={12}>
                <div className="card card-view border-0">
                  <div className="card-body p-4">
                    <div className="mt-3">
                      <div className="table_list">
                        <div className="table-responsive">
                          <table width="100%" className="text-center">
                            <thead>

                              <tr>
                                <th>Date</th>
                                <th>NFT Type</th>
                                <th>NFT Id</th>
                                <th>Price MNT</th>
                                <th>Price USD</th>
                                <th>Purchased By</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userdatahistory.map(
                                (item, index) => (

                                  <tr className="tr_list">
                                    <td>{item.datetime}</td>
                                    <td>{item.editionTypeId == 1 ? "BasicNft" : "Limited Edition"}</td>
                                    <td>
                                      {item.uniqueId}&nbsp;
                                    </td>
                                    <td>
                                      {item.priceMNT}&nbsp;MNT
                                    </td>
                                    <td>${item.priceUSD}&nbsp;</td>
                                    <td><span>{item.purchase_by.slice(0, 4)}......{item.purchase_by.slice(-4)}</span><CopyToClipboard text={item.purchase_by}>
                                      <span title="Click to Copy" className="mr-copylink" id="token-buy-button" onClick={copieBtn} style={{ cursor: "pointer", color: '#fff' }}>
                                        &nbsp;<i class="fa fa-clone "></i></span></CopyToClipboard></td>

                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </Col>
            </Col> */}
            {/* --------------------- */}
            <Modal
              show={showModal}
              className="walker_detail"
              centered
              size="l"
              backdrop="static"
            >
              <Modal.Body>
                <Row>
                  <Vortex
                    visible={showModal == true ? true : false}
                    height="80"
                    width="80"
                    ariaLabel="vortex-loading"
                    wrapperStyle={{}}
                    wrapperClass="vortex-wrapper"
                    colors={[
                      "#6610f2;",
                      "#6f42c1",
                      "blue",
                      "#300d39",
                      "#212529",
                      "purple",
                    ]}
                  />
                  <div className="" style={{ display: "grid" }}>
                    <p style={{ margin: "auto" }}>
                      Please wait.....
                      {MintingTimer && `${MintingTimer} Seconds`}{" "}
                    </p>
                    <p style={{ margin: "auto" }}>{process}</p>
                  </div>
                </Row>
              </Modal.Body>
            </Modal>
            {/* ----------------- */}

            {/* --------------------- */}
            <Modal
              show={showModalLoad}
              className="walker_detail"
              centered
              size="l"
              backdrop="static"
            >
              <Modal.Body>
                <Row>
                  <div className="" style={{ display: "grid" }}>
                    <p style={{ margin: "auto" }}>
                      Your Transaction is in Progress
                    </p>
                    <p style={{ margin: "auto" }}>
                      It will take some time to reflect to your account
                    </p>
                    <p style={{ margin: "auto" }}>
                      Your can check your Transaction by visiting{" "}
                      <a
                        href={`${config.bsc_url}/${buyTransactionHash}`}
                        target="_blank"
                      >
                        {buyTransactionHash.slice(0, 4) +
                          "..." +
                          buyTransactionHash.slice(-4)}
                      </a>
                      <CopyToClipboard text={buyTransactionHash}>
                        <span
                          title="Click to Copy"
                          className="mr-copylink"
                          id="token-buy-button"
                          onClick={copieBtn}
                          style={{ cursor: "pointer", color: "#fff" }}
                        >
                          &nbsp;<i class="fa fa-clone "></i>
                        </span>
                      </CopyToClipboard>{" "}
                    </p>
                  </div>
                </Row>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={redirect}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            {/* ----------------- */}
          </Row>
        </Container>
      </section>

      {/* <Footer /> */}
    </>
  );
};
export default BuyNowMobile;
