import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { Container, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import Dashboardheader from "../directives/dashboardheader";
import "../component/packageplan.css"
import { DisplayAllSubscriptionPlanForUser, DisplaySubscription } from "../Action/user.action";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

const Packageplan = () => {
    const [getplan, setplan] = useState([])

    const loginData = (!Cookies.get('loginSuccessStepMrMint', { domain: 'mrmint.io' })) ? [] : JSON.parse(Cookies.get('loginSuccessStepMrMint', { domain: 'mrmint.io' }));

    useEffect(() => {
        getPackagePlan()

    }, [])

    const redirection = () => {
        window.location.href = `${config.baseUrl}dashboard#subscribedplan`
    }

    const redirection2 = (data) => {
        window.location.href = `${config.baseUrl}nftdetail/${data}`
    }


    const getPackagePlan = async () => {
        if (loginData.id) {

            let res = await DisplayAllSubscriptionPlanForUser();

            if (res.success) {
                setplan(res.data)
            }
            else {
                setplan([])
            }
        }
        else {
            let res = await DisplaySubscription();

            if (res.success) {
                setplan(res.data)
            }
            else {
                setplan([])
            }
        }
    }
    return (
        <>
            {loginData.id ? <Dashboardheader /> : <Header />}
            {/* <Dashboardheader /> */}
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="description" content="Discover the benefits of our Stepmint Package Plan for cost-effective and efficient solutions tailored to your needs. Try it today!" />
                <meta name="title" content="Stepmint Package Plan | Affordable and Effective Solutions" />
                <meta name="keywords" content="Stepmint Package Plan, cost-effective solutions, tailored solutions" />
                <meta name="author" content="StepMint" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <div className="shape_bg">
                <img src="assets/images/shape.svg" className="shape" />
                <img src="assets/images/shape1.svg" className="shape1" />
                <img src="assets/images/shape2.svg" className="shape2" />
                {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
            </div>
            <div className="page-wrapper">
                <div className="position-relative overflow-hidden position-relative overflow-hidden mt-5 pt-2 mb-5">
                    {/* Basic NFT */}
                    <div className="mb-3">
                        <Container className="">
                            <div className="mb-3">
                                <h2 className="text-white">Basic NFTs </h2>
                            </div>
                            <Row>
                                {getplan.filter(item => item.editionTypeId == 1).map(
                                    (item, index) => (
                                        <Col lg={6} className="mb-4">
                                            <div className="card nftplan">
                                                <div className="card-body p-0">
                                                    <div className="p-3 pb-1">
                                                        <table width="100%">
                                                            <tr>
                                                                <th className=""><h5 className="mb-0 text-white">{item.name}</h5></th>
                                                                <td className="">
                                                                    <div>
                                                                        {
                                                                            item.purchased == 1 ? <Button variant="main2" onClick={redirection} className="rounded-1 btn-sm mh-auto pt-2 pb-2 px-4">Subscribed</Button>
                                                                                : <Button variant="main2" className="rounded-1 btn-sm mh-auto pt-2 pb-2 px-4" onClick={() => redirection2(item.id)}>Buy Now</Button>

                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className=""> <p className="mb-0">${item.price}</p></th>
                                                                {/* <td className="text-light-grey">Remaining NFT {item.remaining}</td> */}
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="card-view text-center p-2 rounded-30">
                                                        <img src={`${config.ipfsurl + item.image}`} alt={item.image} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                            </Row>
                        </Container>
                    </div>
                    {/* Limited edition */}
                    <div className="mb-3">
                        <Container className="">
                            <div className="mb-3 mt-5">
                                <h2 className="text-white">Limited Edition </h2>
                            </div>
                            <Row>
                                {getplan.filter(item => item.editionTypeId == 2).map(
                                    (item, index) => (
                                        <Col lg={6} className="mb-4">
                                            <div className="card nftplan">
                                                <div className="card-body p-0">
                                                    <div className="p-3 pb-1">
                                                        <table width="100%">
                                                            <tr>
                                                                <th className=""><h5 className="mb-0 text-white">{item.name}</h5></th>
                                                                <td className="">
                                                                    <div>
                                                                        {
                                                                            item.purchased == 1 ? <Button variant="main2" onClick={redirection} className="rounded-1 btn-sm mh-auto pt-2 pb-2 px-4">Subscribed</Button>
                                                                                : <Button variant="main2" className="rounded-1 btn-sm mh-auto pt-2 pb-2 px-4" onClick={() => redirection2(item.id)}>Buy Now</Button>

                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className=""> <p className="mb-0">${item.price}</p></th>
                                                                {/* <td className="text-light-grey">Remaining NFT {item.remaining}</td> */}
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="card-view text-center p-4 rounded-30">
                                                        <img src={`${config.ipfsurl + item.image}`} alt={item.image} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                            </Row>
                        </Container>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    );
};
export default Packageplan;
