import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { Container, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import Dashboardheader from "../directives/dashboardheader";

const Staking = () => {
  return (
    <>
      <Dashboardheader />
      <div className="shape_bg">
          <img src="assets/images/shape.svg" className="shape" />
          <img src="assets/images/shape1.svg" className="shape1" />
          <img src="assets/images/shape2.svg" className="shape2" />
          {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
        </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          <div className="mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <h1 className="text-gradient">Staking</h1>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ---------- */}
          <div className=" mb-5">
            <Container className="">
                <Row>
                    <Col lg={12}>
                        <div className="staking_banner card-view">
                          <img src="assets/images/star_left.png" className="star_left"/>
                          <img src="assets/images/star_right.png" className="star_right"/>
                          <Row>
                            <Col lg={2}>
                            </Col>
                            <Col lg={8}>
                               <img src="assets/images/staking.png" className="stake_img"/>
                            </Col>
                            <Col lg={2}>
                            </Col>
                          </Row>
                         

                        </div>
                    </Col>
                </Row>

            </Container>

          </div>
          {/* ---------- */}
          <div className=" mb-5 ">
            <Container className="">
              <Row>
                <Col lg={4} className="mb-3">
                  <div className="card-view p-5 text-center">
                    <p className="text-light-grey">Total Staking</p>
                    <h2 className="text-gradient font-inherit"><b>12,355,45</b></h2>
                  </div>
                </Col>
                <Col lg={4} className="mb-3">
                  <div className="card-view p-5 text-center">
                    <p className="text-light-grey">Total Staking Transactions</p>
                    <h2 className="text-gradient font-inherit"><b>50,546,000</b></h2>
                  </div>
                </Col>
                <Col lg={4} className="mb-3">
                  <div className="card-view p-5 text-center">
                    <p className="text-light-grey">Total Staking Income</p>
                    <h2 className="text-gradient font-inherit"><b>43,501</b></h2>
                  </div>
                </Col>
              
              </Row>
            </Container>
          </div>
          {/* ---------- */}
        
          <div className="latest_transaction mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <div className="card card-view2">
                    <div className="card-body p-4">
                      <h2 className="text-gradient mb-0">
                        <b>Total Staked Value</b>
                      </h2>
                      <div>
                        
                      </div>
                      <div className="mt-5">
                        <div className="table-responsive">
                            <table width="100%" className="text-center">
                               
                                    <tr className="table-head">
                                        <th>Characteristic</th>
                                        <th>Staked value (in BNB Amount)</th>
                                        <th>Total stake</th>
                                        <th>Reward</th>
                                    </tr>
                                    <tr>
                                        <td>BNB Chain</td>
                                        <td>$100</td>
                                        <td>50,000,20</td>
                                        <td className="text-success">3.52%</td>
                                    </tr>
                                    <tr>
                                        <td>BNB Chain</td>
                                        <td>$100</td>
                                        <td>50,000,20</td>
                                        <td className="text-success">3.52%</td>
                                    </tr>
                                    <tr>
                                        <td>BNB Chain</td>
                                        <td>$100</td>
                                        <td>50,000,20</td>
                                        <td className="text-success">3.52%</td>
                                    </tr>
                                    <tr>
                                        <td>BNB Chain</td>
                                        <td>$100</td>
                                        <td>50,000,20</td>
                                        <td className="text-success">3.52%</td>
                                    </tr>
                                    <tr>
                                        <td>BNB Chain</td>
                                        <td>$100</td>
                                        <td>50,000,20</td>
                                        <td className="text-success">3.52%</td>
                                    </tr>
                                    <tr>
                                        <td>BNB Chain</td>
                                        <td>$100</td>
                                        <td>50,000,20</td>
                                        <td className="text-success">3.52%</td>
                                    </tr>
                                    <tr>
                                        <td>BNB Chain</td>
                                        <td>$100</td>
                                        <td>50,000,20</td>
                                        <td className="text-success">3.52%</td>
                                    </tr>
                                    <tr>
                                        <td>BNB Chain</td>
                                        <td>$100</td>
                                        <td>50,000,20</td>
                                        <td className="text-success">3.52%</td>
                                    </tr>
                                   
                                    <tr>
                                        <td>BNB Chain</td>
                                        <td>$100</td>
                                        <td>50,000,20</td>
                                        <td className="text-success">3.52%</td>
                                    </tr>
                                    
                                    <tr>
                                        <td>BNB Chain</td>
                                        <td>$100</td>
                                        <td>50,000,20</td>
                                        <td className="text-success">3.52%</td>
                                    </tr>
                                    
                            </table>

                        </div>
                       
                        <div className="text-right mt-4">
                          <Button variant="custom" className="text-white px-5">
                            See all
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Staking;
