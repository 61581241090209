import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Accordion,
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation, Pagination } from "swiper/modules";

const Stakenft = () => {
  return (
    <>
      <Header />

      <section className="position-relative overflow-hidden">
        <div className="shape_bg">
          <img src="assets/images/shape.svg" className="shape" />
          <img src="assets/images/shape1.svg" className="shape1" />
          <img src="assets/images/shape2.svg" className="shape2" />
        </div>
        <Container>
          <Col lg={12}>
            <div>
              <Fade right>
              <h1 className="text-gradient">Stake NFT</h1>
              </Fade>
<Fade left>
              <p className="text-light-grey mt-3">
                Deposit and stake your Bored Ape Yacht Club (BAYC), Mutant Ape
                Yacht Club (MAYC) and Bored Ape Kennel Club (BAKC) NFTs using
                our seamless process to earn daily ApeCoin (APE) rewards!
              </p>
              </Fade>
            </div>
          </Col>
          <Col lg={12} className="mt-5">
            <div className="nftbox">
              <Zoom>
              <div className="box-heading">
                <h3>Man NFTs</h3>
              </div>
              </Zoom>
              <div className="mt-5">
                <Container>
                  <Row>
                    <Col lg={4} className="mb-5">
                      <Roll left>
                        <div className="cardList">
                          <Card className="p-2">
                            <div className="overflow-hidden">
                              <Card.Img
                                variant="top"
                                src="assets/images/card1.png"
                              />
                            </div>
                            <Card.Body>
                              <Row>
                                <Col lg={6} xs={7}>
                                  <div>
                                    <h6 className="text-white">
                                      <span className="text-light-grey">
                                        Own by
                                      </span>
                                      &nbsp;StarWallets
                                    </h6>
                                    <p className="text-white mb-2">$10,000</p>
                                  </div>
                                  <Button
                                    variant="custom"
                                    className="text-white px-3"
                                  >
                                    Stake Now
                                  </Button>
                                </Col>
                                <Col lg={6} xs={5} className="text-right">
                                  <div className="ratingstar">
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                  </div>
                                  <p className="text-light-grey mb-0">
                                    Total Supply
                                  </p>
                                  <small className="text-white">988/1000</small>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </Roll>
                    </Col>
                    <Col lg={4} className="mb-5">
                      <Roll left>
                        <div className="cardList">
                          <Card className="p-2">
                            <div className="overflow-hidden">
                              <Card.Img
                                variant="top"
                                src="assets/images/card2.png"
                              />
                            </div>
                            <Card.Body>
                              <Row>
                                <Col lg={6} xs={7}>
                                  <div>
                                    <h6 className="text-white">
                                      <span className="text-light-grey">
                                        Own by
                                      </span>
                                      &nbsp;StarWallets
                                    </h6>
                                    <p className="text-white mb-2">$10,000</p>
                                  </div>
                                  <Button
                                    variant="custom"
                                    className="text-white px-3"
                                  >
                                    Stake Now
                                  </Button>
                                </Col>
                                <Col lg={6} xs={5} className="text-right">
                                  <div className="ratingstar">
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                  </div>
                                  <p className="text-light-grey mb-0">
                                    Total Supply
                                  </p>
                                  <small className="text-white">988/1000</small>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </Roll>
                    </Col>
                    <Col lg={4} className="mb-5">
                      <Roll left>
                        <div className="cardList">
                          <Card className="p-2">
                            <div className="overflow-hidden">
                              <Card.Img
                                variant="top"
                                src="assets/images/card3.png"
                              />
                            </div>
                            <Card.Body>
                              <Row>
                                <Col lg={6} xs={7}>
                                  <div>
                                    <h6 className="text-white">
                                      <span className="text-light-grey">
                                        Own by
                                      </span>
                                      &nbsp;StarWallets
                                    </h6>
                                    <p className="text-white mb-2">$10,000</p>
                                  </div>
                                  <Button
                                    variant="custom"
                                    className="text-white px-3"
                                  >
                                    Stake Now
                                  </Button>
                                </Col>
                                <Col lg={6} xs={5} className="text-right">
                                  <div className="ratingstar">
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                  </div>
                                  <p className="text-light-grey mb-0">
                                    Total Supply
                                  </p>
                                  <small className="text-white">988/1000</small>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </Roll>
                    </Col>
                    <Col lg={4} className="mb-5">
                      <Roll left>
                        <div className="cardList">
                          <Card className="p-2">
                            <div className="overflow-hidden">
                              <Card.Img
                                variant="top"
                                src="assets/images/card4.png"
                              />
                            </div>
                            <Card.Body>
                              <Row>
                                <Col lg={6} xs={7}>
                                  <div>
                                    <h6 className="text-white">
                                      <span className="text-light-grey">
                                        Own by
                                      </span>
                                      &nbsp;StarWallets
                                    </h6>
                                    <p className="text-white mb-2">$10,000</p>
                                  </div>
                                  <Button
                                    variant="custom"
                                    className="text-white px-3"
                                  >
                                    Stake Now
                                  </Button>
                                </Col>
                                <Col lg={6} xs={5} className="text-right">
                                  <div className="ratingstar">
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                  </div>
                                  <p className="text-light-grey mb-0">
                                    Total Supply
                                  </p>
                                  <small className="text-white">988/1000</small>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </Roll>
                    </Col>
                    <Col lg={4} className="mb-5">
                      <Roll left>
                        <div className="cardList">
                          <Card className="p-2">
                            <div className="overflow-hidden">
                              <Card.Img
                                variant="top"
                                src="assets/images/card5.png"
                              />
                            </div>
                            <Card.Body>
                              <Row>
                                <Col lg={6} xs={7}>
                                  <div>
                                    <h6 className="text-white">
                                      <span className="text-light-grey">
                                        Own by
                                      </span>
                                      &nbsp;StarWallets
                                    </h6>
                                    <p className="text-white mb-2">$10,000</p>
                                  </div>
                                  <Button
                                    variant="custom"
                                    className="text-white px-3"
                                  >
                                    Stake Now
                                  </Button>
                                </Col>
                                <Col lg={6} xs={5} className="text-right">
                                  <div className="ratingstar">
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                  </div>
                                  <p className="text-light-grey mb-0">
                                    Total Supply
                                  </p>
                                  <small className="text-white">988/1000</small>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </Roll>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Col>
          <Col lg={12} className="mt-5">
            <div className="nftbox">
            <Zoom>
              <div className="box-heading">
                <h3>Woman NFTs</h3>
              </div>
              </Zoom>
              <div className="mt-5">
                <Container>
                  <Row>
                    <Col lg={4} className="mb-5">
                      <Roll left>
                        <div className="cardList">
                          <Card className="p-2">
                            <div className="overflow-hidden">
                              <Card.Img
                                variant="top"
                                src="assets/images/card1.png"
                              />
                            </div>
                            <Card.Body>
                              <Row>
                                <Col lg={6} xs={7}>
                                  <div>
                                    <h6 className="text-white">
                                      <span className="text-light-grey">
                                        Own by
                                      </span>
                                      &nbsp;StarWallets
                                    </h6>
                                    <p className="text-white mb-2">$10,000</p>
                                  </div>
                                  <Button
                                    variant="custom"
                                    className="text-white px-3"
                                  >
                                    Stake Now
                                  </Button>
                                </Col>
                                <Col lg={6} xs={5} className="text-right">
                                  <div className="ratingstar">
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                  </div>
                                  <p className="text-light-grey mb-0">
                                    Total Supply
                                  </p>
                                  <small className="text-white">988/1000</small>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </Roll>
                    </Col>
                    <Col lg={4} className="mb-5">
                      <Roll left>
                        <div className="cardList">
                          <Card className="p-2">
                            <div className="overflow-hidden">
                              <Card.Img
                                variant="top"
                                src="assets/images/card2.png"
                              />
                            </div>
                            <Card.Body>
                              <Row>
                                <Col lg={6} xs={7}>
                                  <div>
                                    <h6 className="text-white">
                                      <span className="text-light-grey">
                                        Own by
                                      </span>
                                      &nbsp;StarWallets
                                    </h6>
                                    <p className="text-white mb-2">$10,000</p>
                                  </div>
                                  <Button
                                    variant="custom"
                                    className="text-white px-3"
                                  >
                                    Stake Now
                                  </Button>
                                </Col>
                                <Col lg={6} xs={5} className="text-right">
                                  <div className="ratingstar">
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                  </div>
                                  <p className="text-light-grey mb-0">
                                    Total Supply
                                  </p>
                                  <small className="text-white">988/1000</small>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </Roll>
                    </Col>
                    <Col lg={4} className="mb-5">
                      <Roll left>
                        <div className="cardList">
                          <Card className="p-2">
                            <div className="overflow-hidden">
                              <Card.Img
                                variant="top"
                                src="assets/images/card3.png"
                              />
                            </div>
                            <Card.Body>
                              <Row>
                                <Col lg={6} xs={7}>
                                  <div>
                                    <h6 className="text-white">
                                      <span className="text-light-grey">
                                        Own by
                                      </span>
                                      &nbsp;StarWallets
                                    </h6>
                                    <p className="text-white mb-2">$10,000</p>
                                  </div>
                                  <Button
                                    variant="custom"
                                    className="text-white px-3"
                                  >
                                    Stake Now
                                  </Button>
                                </Col>
                                <Col lg={6} xs={5} className="text-right">
                                  <div className="ratingstar">
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                  </div>
                                  <p className="text-light-grey mb-0">
                                    Total Supply
                                  </p>
                                  <small className="text-white">988/1000</small>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </Roll>
                    </Col>
                    <Col lg={4} className="mb-5">
                      <Roll left>
                        <div className="cardList">
                          <Card className="p-2">
                            <div className="overflow-hidden">
                              <Card.Img
                                variant="top"
                                src="assets/images/card4.png"
                              />
                            </div>
                            <Card.Body>
                              <Row>
                                <Col lg={6} xs={7}>
                                  <div>
                                    <h6 className="text-white">
                                      <span className="text-light-grey">
                                        Own by
                                      </span>
                                      &nbsp;StarWallets
                                    </h6>
                                    <p className="text-white mb-2">$10,000</p>
                                  </div>
                                  <Button
                                    variant="custom"
                                    className="text-white px-3"
                                  >
                                    Stake Now
                                  </Button>
                                </Col>
                                <Col lg={6} xs={5} className="text-right">
                                  <div className="ratingstar">
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                  </div>
                                  <p className="text-light-grey mb-0">
                                    Total Supply
                                  </p>
                                  <small className="text-white">988/1000</small>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </Roll>
                    </Col>
                    <Col lg={4} className="mb-5">
                      <Roll left>
                        <div className="cardList">
                          <Card className="p-2">
                            <div className="overflow-hidden">
                              <Card.Img
                                variant="top"
                                src="assets/images/card5.png"
                              />
                            </div>
                            <Card.Body>
                              <Row>
                                <Col lg={6} xs={7}>
                                  <div>
                                    <h6 className="text-white">
                                      <span className="text-light-grey">
                                        Own by
                                      </span>
                                      &nbsp;StarWallets
                                    </h6>
                                    <p className="text-white mb-2">$10,000</p>
                                  </div>
                                  <Button
                                    variant="custom"
                                    className="text-white px-3"
                                  >
                                    Stake Now
                                  </Button>
                                </Col>
                                <Col lg={6} xs={5} className="text-right">
                                  <div className="ratingstar">
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                    <img src="assets/images/icon/ratingstar.png" />
                                  </div>
                                  <p className="text-light-grey mb-0">
                                    Total Supply
                                  </p>
                                  <small className="text-white">988/1000</small>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </Roll>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Col>
          <Col lg={12} className="mt-5">
            <Fade left>
            <div className="">
              <h3 className="text-gradient">
                NFT Staking: A Quick Guide On What To Know Before Staking
              </h3>
              <div>
                <small className="text-light-grey">05/07/2023</small>
              </div>
              <br />
              <h3 className="text-gradient mb-3">Main Takeaways</h3>
              <ul className="text-white">
                <li className="mb-1">
                  <small className="text-light-grey">
                    Similar to how it works with proof-of-stake
                    cryptocurrencies, NFT staking is the process of locking up
                    your digital assets to receive rewards.
                  </small>
                </li>
                <li className="mb-1">
                  <small className="text-light-grey">
                    NFT staking opens up a new opportunity for NFT holders to
                    earn rewards on their assets while still maintaining
                    ownership of them.
                  </small>
                </li>
                <li className="mb-1">
                  <small className="text-light-grey">
                    Users who own NFTs within the Bored Ape Yacht Club (BAYC)
                    ecosystem can participate in Binance NFT’s Ape NFT Staking
                    Program to earn daily rewards.
                  </small>
                </li>
              </ul>
            </div>
            </Fade>
            <Fade right>
            <div className="bg_mv text-white p-3 rounded mt-4">
              <p className="mb-0">
                Not sure what you can do with non-fungible tokens (NFTs) sitting
                in your digital wallet? NFT holders can lock their assets on
                staking platforms to earn rewards – all without the need to sell
                their collectibles.
              </p>
            </div>
            </Fade>
           
            <div className="mt-5 mb-5">
              <Zoom>
              <img src="assets/images/stakenft.png" width="100%" />
              </Zoom>
              <Fade left>
              <p className="mt-4">
                A non-fungible token, or NFT, is a digital asset stored on a
                blockchain that holds metadata and unique identifiers that
                distinguish them from other tokens, making each NFT unique. NFTs
                have transformed from a niche technology within the blockchain
                space into a cultural phenomenon in just a few short years. As
                the billion-dollar digital art market continues to grow,
                creators and collectors are exploring new use cases for this
                unique asset class. One of the many new use cases is staking.
                Keep reading to learn about NFT staking and the factors you
                should consider before staking your digital collectibles.
              </p>
              </Fade>
            </div>
            
            <Fade right>
            <div className="mb-5">
              <h3 className="text-gradient mb-3">What is NFT Staking?</h3>
              <p>
                NFT staking refers to locking up NFTs on a platform or protocol
                to earn rewards and other privileges. This allows NFT holders to
                put their idle assets to work without having to sell them.
              </p>

              <p>
                Staking an NFT works like staking cryptocurrency; all you need
                is a Web3 wallet. That said, not all NFTs can be staked. If
                you’re considering buying digital collectibles with a view to
                stake them, double-check that your preferred staking service
                supports the collection before making a purchase.
              </p>
            </div>
            </Fade>
            <Fade left>
            <div className="mb-5">
              <h3 className="text-gradient mb-3">How Does NFT Staking Work?</h3>
              <p>
                While NFT staking is relatively new, it works similarly to
                locking proof-of-stake (PoS) cryptocurrencies. The main idea
                behind PoS staking is that participants can lock their funds on
                a blockchain protocol to support the network. Each time a new
                block is added to the chain, tokens are distributed to
                validators as staking rewards. By locking their coins on a
                blockchain, token holders are able to help support the operation
                of the network while making their HODLed assets work for them in
                exchange for rewards.
              </p>

              <p>
                With NFT staking, you lock your NFTs on a platform for a
                specified amount of time to receive rewards. One benefit that
                collectors and the larger NFT marketplace gain from NFTs being
                staked is that it can produce a deflationary supply pressure,
                resulting in an increase of the price of similar NFTs still in
                circulation.
              </p>

              <p>
                The rewards you’ll get depend on factors such as the annual
                interest rate offered, staking duration, and number of NFTs
                staked. The type of rewards NFT holders get depends on the
                platform used and the kind of NFT staked.
              </p>

              <p>
                Staking rewards are typically issued in a platform’s native
                token, which can be traded for other cryptocurrencies or cash.
                Play-to-earn blockchain gaming platforms are among some of the
                most prominent players in the NFT staking space as in-game
                assets that power the gameplay often come in the form of NFTs.
                Some NFT projects maintain decentralized autonomous
                organizations (DAOs), and holders can lock up their assets in
                the DAO pool to participate in the project's governance.
              </p>

              <p>
                For more information on DAOs, learn more from our article about
                What is a DAO and How Does it Benefit NFTs.
              </p>

              <p>
                Each platform that offers staking will also have its own way of
                calculating interest rates that incentivize NFT holders to
                deposit their assets for as long as possible. While some
                services may offer high interest rates, please consider
                associated risks. Interest rates that sound too good to be true
                could be a sign that the platform is unreliable. Always do your
                own research (DYOR) and make sure you’re aware of the risks
                involved before depositing your NFT on a staking protocol.
              </p>
            </div>
            </Fade>
            <Fade right>
            <div className="mb-5">
              <h3 className="text-gradient mb-3">
                What Are the Pros and Cons of Staking My NFTs?
              </h3>

              <p>
                Here are a few factors for you to consider as you decide whether
                staking your NFTs is the right choice.
              </p>

              <p><b>Pros of NFT staking</b></p>

              <p>Putting your idle digital assets to work</p>

              <p>
                If you've been holding on to an NFT for a while and don't plan
                on selling it anytime soon, staking opens up a great opportunity
                to put your idle digital assets to work. You can lock your NFT
                on a staking platform and reap the benefits without giving up
                your ownership.
              </p>

              <p><b>Engaging with projects and communities</b></p>

              <p>
                Although the exact rewards and perks of staking an NFT will
                differ between projects, a common factor is that most projects
                will reward users who stake their NFTs with utility tokens.
                These tokens may have additional benefits, such as voting and
                governance rights to decide on the project's future direction.{" "}
              </p>

              <p><b>Cons of NFT staking</b></p>

              <p><b>Potential for scams</b></p>

              <p>
                While earning rewards on your NFTs sounds incredibly attractive,
                remember there are risks involved. The NFT industry is still
                growing, and it can be hard to discern who is trustworthy and
                who is a bad actor in such a new space. It’s not a far-fetched
                scenario for an unscrupulous staking platform to misappropriate
                user funds and run away with their tokens.
              </p>

              <p>
                The best way to keep abreast of potential scams is to do your
                own due diligence. Always research and investigate the staking
                platform and the team behind it: it’s always better to err on
                the side of caution.
              </p>

              <p>
                To learn more about how you can keep yourself safe from common
                NFT scams, check out our article on Common NFT Scams & Safety
                Tips.
              </p>


              <p><b>Price volatility</b></p>

              <p>
                While locked in, your NFT could see a significant rise or drop
                in value due to developments in the market and digital art
                scene. Depending on the terms of your staking platform, you may
                not be able to withdraw an NFT that has a long lock-up period.
                However, if holding long-term has always been your intention,
                temporary peaks and dips in the market should be less of a
                concern.
              </p>
            </div>
            </Fade>
            <Fade left>
            <div className="mb-5">
              <h3 className="text-gradient mb-3">
              Can You Claim Rewards Before the Lock-up Period Ends?
              </h3>
              <p>The rewards distribution schedule and lock-up period will depend on the platform you’re staking on. Some services offer flexible lock-up periods, while others will have you to commit to a pre-agreed period strictly. Some platforms may reward users daily during the entire staking period, while others may only release rewards once the staking period is over. Before you decide to stake your NFT, it's best to do your due diligence and choose a program that aligns with your needs and goals. </p>

              
            </div>
            </Fade>
            <Fade right>
            <div className="mb-5">
              <h3 className="text-gradient mb-3">
              Go Ape and Stake Your NFTs On Binance NFT
              </h3>
              <p>Bored Ape Yacht Club (BAYC) and Mutant Ape Yacht Club (MAYC) NFT owners can stake their NFTs on our platform to earn daily ApeCoin (APE) rewards via the Ape NFT Staking Program. Featuring a one-click to stake experience, top-notch security, and zero gas fees, the Ape NFT Staking Program is one of the most convenient ways to stake your BAYC or MAYC NFTs. Users can lock up their BAYC or MAYC NFTs for a flexible term. Plus, stakers don’t need to hold ApeCoins to join the program.</p>

              <p>Please note that daily APE rewards and the annual percentage rate (APR) displayed on the Ape NFT Staking Program page are subject to change on a daily basis.</p>

              <p>For more information, refer to the Frequently Asked Questions on the Ape NFT Staking Program page. </p>

              
            </div>
            </Fade>
            <Fade left>
            <div className="mb-5">
              <h3 className="text-gradient mb-3">
              Conclusion
              </h3>
              <p>NFT staking allows participants to earn extra rewards from their idle digital collectibles. And if the NFT project has a DAO, NFT holders can stake their digital assets in its pool to play a part in the project's governance. The introduction of NFT staking has unlocked new use cases for this asset class that go beyond just collecting. While it might be too early to tell, we’ll most likely see more new staking opportunities being created in the NFT space in the near future. </p>

             

              
            </div>
            </Fade>
            <Fade right>
            <div className="mb-5">
              <h3 className="text-gradient mb-3">
              Embark On Your NFT Adventure Today
              </h3>
              <p>Check out exclusive NFT collections on StarWallets NFT, a marketplace where artists, digital creators, and NFT enthusiasts come together to trade non-fungible artwork, collectible items, and more. Follow us on Twitter and Telegram to hear about the latest news and promotions, such as our weekly IGO and exclusive Mystery Box drops! </p>

             

              
            </div>
            </Fade>
          </Col>
        </Container>
      </section>

      <Footer />
    </>
  );
};
export default Stakenft;
