import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { Container, Row, Col, Button, Card, Tab, Nav } from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation, Pagination } from "swiper/modules";

const Bnbstaking = () => {
  return (
    <>
      <Header />
      <section className="staking position-relative">
        <div className="shape_bg">
          <img src="assets/images/shape.svg" className="shape" />
          <img src="assets/images/shape1.svg" className="shape1" />
          <img src="assets/images/shape2.svg" className="shape2" />
        </div>
        <Container>
          <Row className="align-items-center">
            <Col lg={6} className="text-center">
              <Zoom>
                <div className="starcoin">
                  <img src="assets/images/bndusdt.gif" />
                </div>
              </Zoom>
            </Col>
            <Col lg={6}>
              <Fade right>
                <div>
                  <h1 className="text-white">
                    BNB/USD Staking: Unlock the Power of Your BNB Holdings!
                  </h1>
                  <p className="">
                    Are you a proud holder of Binance Coin (BNB) and looking for
                    ways to maximise its potential? Look no further! StarWallets
                    BNB/USD Staking is here to help you unlock the power of your
                    BNB holdings and earn attractive rewards in the process.
                  </p>
                </div>
                {/* <Button variant="main2" className="px-4 text-white">
                  Learn More
                </Button> */}
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="position-relative">
        <div className="leftshadow">
          <img src="assets/images/blueleft.png"/>

        </div>
        <Container>
          <Col lg={12}>
            
            <div>
            <Slide top>
              <h1 className="text-gradient">
                Why choose StarWallets BNB/USD Staking?
              </h1>
              </Slide>
              <Fade left>
              <p className="text-light-grey">
                * Earn Passive Income: By staking your BNB, you can earn passive
                income effortlessly. Instead of letting your BNB sit idle, why
                not put it to work and let it generate additional income for
                you? With BNB Staking, you can enjoy daily rewards simply by
                holding your BNB in a secure staking pool.
              </p>

              <p className="text-light-grey">
                * Higher Yields: BNB Staking offers competitive yields that
                outshine traditional investment options. Take advantage of our
                staking program to earn more than what traditional savings or
                investment accounts can offer. We believe in rewarding our
                users, and that's why we offer attractive staking rewards to
                help you grow your BNB holdings.
              </p>

              <p className="text-light-grey">
                * Flexibility and Accessibility: StarWallets BNB/USD Staking is
                designed to be user-friendly and accessible to all. Whether
                you're a seasoned investor or new to the world of crypto, our
                staking platform is intuitive and easy to navigate. You can
                stake your BNB with just a few clicks, and our transparent
                interface allows you to track your staking rewards in real-time.
              </p>

              <p className="text-light-grey">
                * Security and Trust: Your peace of mind is our top priority.
                BNB Staking operates on a secure and robust platform, backed by
                industry-leading security measures. We employ cutting-edge
                technology to ensure the safety of your BNB holdings. Rest
                assured, your assets are protected, and you can stake with
                confidence.
              </p>

              <p className="text-light-grey">
                * Support for the Binance Ecosystem: By participating in
                StarWallets BNB Staking, you contribute to the growth and
                development of the Binance ecosystem. Your staked BNB helps
                secure the network and facilitates the smooth operation of
                Binance Chain and Binance Smart Chain. Join us in building a
                stronger crypto community!
              </p>
              </Fade>
            </div>
            
          </Col>
          <Col lg={12} className="mt-5">
            
            <div>
              <Slide top>
              <h1 className=" text-gradient">
                {" "}
                How to get started with BNB Staking:
              </h1>
              </Slide>
              <Fade left>
              <p className="text-light-grey">
                * Create an Account: If you don't have an account already, sign
                up on our platform. It's quick, easy, and secure.
              </p>

              <p className="text-light-grey">
                * Deposit BNB: Transfer your BNB to your staking wallet. Your
                funds remain under your control at all times.
              </p>

              <p className="text-light-grey">
                * Choose a Staking Plan: Select the staking plan that suits your
                preferences. We offer various staking options with different
                durations and rewards. Find the plan that aligns with your
                investment goals.
              </p>

              <p className="text-light-grey">
                * Start Staking: Confirm your staking selection, and you're all
                set! Sit back, relax, and watch your BNB work for you. Enjoy
                daily staking rewards directly deposited into your account.
              </p>

              <p className="text-light-grey">
                * Monitor and Manage: Keep track of your staking rewards and the
                performance of your BNB holdings through our user-friendly
                dashboard. You have full control over your staked assets and can
                withdraw or restake them as desired.
              </p>
              </Fade>
            </div>
            <p className="text-light-grey">
              Don't miss out on the opportunity to make your BNB work harder for
              you. Stake your BNB today with BNB Staking and unlock a world of
              rewards and possibilities. Start earning passive income daily and
              watch your BNB holdings grow!
              
            </p>
           
          </Col>
          <Col lg={12} className="mt-5">
            <Fade right>
            <div className="bg_mv text-white p-3 rounded">
            <p className="mb-0">Disclaimer: Staking involves risks, including the potential loss of principal. The information provided in this here is for informational purposes only and should not be considered financial or investment advice. Please do your own research and consult with a professional advisor before making any investment decisions.</p>
            </div>
            </Fade>

          </Col>
        </Container>
      </section>

      <Footer />
    </>
  );
};
export default Bnbstaking;
