import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import { GetLdp2Action, Ldp2UpdateAction } from '../../Action/user.action';
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import Dashboardheader from '../../directives/dashboardheader';
import Swal from 'sweetalert2';
import { approveTokenForLDP, getBalanceBlockchain, getPayableAmountBlockchain, joinLdpBlockchain } from './LDPBlockchain';
import QRCode from 'react-qr-code';
import config from '../../config/config';
import { useNavigate } from 'react-router-dom';

const LDP2Qualifier = () => {
    const loginData = !Cookies.get("loginSuccessStepMrMint", {
        domain: "mrmint.io",
    })
        ? []
        : JSON.parse(
            Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
        );

    const loginData1 = !localStorage.getItem("loginSuccessStepMrMint")
        ? []
        : JSON.parse(localStorage.getItem("loginSuccessStepMrMint"));
    const [mobile, setMobile] = useState(loginData1.mobile || "");
    const [address, setAddress] = useState(loginData1.address || "");
    const [paymentOption, setPaymentOption] = useState(loginData1.paymentOption || "USDT");
    const [getLdp2Data, setLdp2Data] = useState({})
    const [connectWalletAddress, setConnectWalletAddress] = useState("");
    const [currentBNBBalance, setcurrentBNBBalance] = useState("0.000000");
    const [payableAmount, setPayableAmount] = useState(0);
    const [currentTokenBalance, setCurrentTokenBalance] = useState({ MNT: 0, USDT: 0 });
    const [currentLdpPlan, setCurrentLdpPlan] = useState(2);
    const [loader, setLoader] = useState(false)
    const [paymentLoader, setPaymentLoader] = useState(false)
    const [ldpStatus, setLdpStatus] = useState(0)
    const navigate = useNavigate()

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        Swal.fire({
            title: 'Alert!',
            text: 'Kindly refresh the page and clear your cache to proceed further.'
            
        }).then(() => {
            setTimeout(() => {
                window.location.reload();
            }, 300);
        });
      };

    useEffect(() => {
        getLdp2UserData();
        setTimeout(() => {
            if (loginData.id) {
                getBNBBalance();
            }
        }, 500);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            console.log(loginData1, "loginData1");
        }, 1000);
    }, []);

    const getBNBBalance = async () => {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const res = await getBalanceBlockchain(accounts[0]);
            if (res.status) {
                setConnectWalletAddress(accounts[0]);
                setCurrentTokenBalance(res);
            }
        }
    };

    useEffect(() => {
        if (paymentOption && connectWalletAddress) {
            getPayableAmount();
        }
    }, [paymentOption, connectWalletAddress]);

    const getPayableAmount = async () => {
        const res = await getPayableAmountBlockchain(paymentOption, currentLdpPlan, connectWalletAddress);
        if (res.status) {
            setPayableAmount(res.amount);
        }
    }

    const getLdp2UserData = async () => {
        setLoader(true)
        let res = await GetLdp2Action();
        if (res.success) {
            if (res.data.ldpId) {
                setLdp2Data(res.data)
                setLoader(false)
            } else {
                // toast.error("Sorry You are not qualifing in LDP program")
                // navigate(`${config.baseUrl}dashboard`)
                setLoader(false)

                setLdpStatus(1)
            }

        }
        else {
            setLoader(false)
            setLdp2Data([])
        }
    }

    const handleClick = async (isAttending) => {
        if (isAttending) {
            if (!mobile || !address) {
                toast.error('Please fill in all the details.');
                return;
            }
        }
        let text = ''
        if (getLdp2Data.qualifyCount >= 1) {
            text = isAttending ? `Reserve Your Seat Today!. & Confirmed by just paying $1000 Worth ${paymentOption} token. ` : `YAre you sure you do not want to attend the LDP program?`;

        } else {
            text = isAttending ? `Reserve Your Seat Today!.` : `By clicking "Disagree," your participation in this program will be canceled. Are you sure?`;
        }

        const result = await Swal.fire({
            // title: 'Are you sure?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        });

        if (result.isConfirmed) {
            // User clicked "Yes"
            const obj = {
                user_id: loginData.id,
                id: getLdp2Data.ldpId,
                isAttending,
                mobile,
                address,
                qualifyCount: getLdp2Data.qualifyCount,
                updated:true
            };

            if (getLdp2Data.qualifyCount >= 1 && isAttending === 1) {
                const payRes = await joinLdp();
                if (payRes) {
                    await Swal.fire({
                        title: 'Please wait...',
                        text: 'Processing your request. Please wait for 1 minute.',
                        icon: 'info',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        timer: 60000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });
                    await Ldp2UpdateAction(obj);

                    Swal.fire({
                        title: 'Success!',
                        text: 'Your payment was processed successfully.',
                        icon: 'success'
                    }).then(() => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 300);
                    });
                }
                // else{
                //     toast.error(res.message)
                // }

            } else {
                const resp = await Ldp2UpdateAction(obj);
                if(resp.isCacheClear){
                    clearCacheData()
                }else{
                    toast.success('Your response is saved.');
                    setTimeout(() => {
                        window.location.reload();
                    }, 300);
                }
            }
        }
    };

    const joinLdp = async () => {
        setPaymentLoader(true);

        // Show Swal alert while payment is processing
        const swalWait = Swal.fire({
            title: 'Please wait...',
            text: 'Your payment is being processed. This may take a few moments.',
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        if (currentTokenBalance[paymentOption] < payableAmount) {
            toast.error(`Insufficient ${paymentOption} balance in your wallet`);
            setPaymentLoader(false);
            Swal.close();
            return;
        }

        let allowance = (paymentOption === 'MNT') ? currentTokenBalance['mntAllowance'] : currentTokenBalance['usdtAllowance'];
        if (allowance < payableAmount) {
            const approveRes = await approveTokenForLDP(paymentOption, currentLdpPlan, connectWalletAddress);
            if (!approveRes.status) {
                toast.error(approveRes.message);
                setPaymentLoader(false);
                Swal.close();
                return;
            }
        }

        const res = await joinLdpBlockchain(paymentOption, currentLdpPlan, connectWalletAddress, getLdp2Data.ldpId);
        if (!res.status) {
            toast.error(res.message)
            setPaymentLoader(false);
            Swal.close();
            return;
        }

        if (res.status) {
            setPaymentLoader(false);
            Swal.close();
            toast.success("Transaction successful!");
            return true;
        }

        setPaymentLoader(false);
        Swal.close();
    };


    return (
        <>
            <Dashboardheader />
            <div className="shape_bg">
                <img src="assets/images/shape.svg" className="shape" />
                <img src="assets/images/shape1.svg" className="shape1" />
                <img src="assets/images/shape2.svg" className="shape2" />
                {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
            </div>
            {loader ? (
                <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : ldpStatus ? (
                <div className="text-center d-flex justify-content-center align-items-center" style={{ padding: '20px', color: "#d3d3d3", borderRadius: '8px' ,height:"80vh",  }}>
                    <h4>You are not qualified for LDP program</h4>
                </div>
            ) : getLdp2Data.isCheckout === 1 ? (
                <div className="text-center d-flex justify-content-center align-items-center" style={{ padding: '20px', color: "#d3d3d3", borderRadius: '8px', height:"80vh",  }}>
                    <h4>No data found</h4>
                </div>
            ) : (getLdp2Data.isCheckout === 0 || getLdp2Data.isCheckout === null) && getLdp2Data.isAttending === 0 && getLdp2Data.qrCode === null ? (
                <div className="text-center d-flex justify-content-center align-items-center" style={{ padding: '20px', color: "#d3d3d3", borderRadius: '8px', height:"80vh",  }}>
                    <h4>No event found</h4>
                </div>
            ) : (Object.keys(getLdp2Data).length && getLdp2Data.qrCode != null && !getLdp2Data.isAttending && !getLdp2Data.isCheckout) ? <>
                <div className="share_referral text-center" style={{ padding: '20px' }}>
                    <h4 style={{ color: "#d3d3d3", marginBottom: '20px' }}>LDP 2 QR Code</h4>
                    <div style={{ background: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', display: 'inline-block' }}>
                        <QRCode
                            value={getLdp2Data.qrCode}
                            size={230}
                            style={{ display: 'block', margin: '0 auto', width: "100%", height: "100%", }}
                        />
                        <h3 className='mt-3'>{getLdp2Data.qrCode}</h3>
                    </div>
                </div>
            </> : (

                <div className=''>
                    <div className="container">
                        <br />
                        {getLdp2Data.qualifyCount >= 1 ? (
                            <>
                                <h2 className="text-left ldp-msg text-capitalize">Congratulations You’ve successfully re-qualified for LDP 2 Program.</h2>
                            </>
                        ) : (
                            <>
                                <h2 className="text-left ldp-msg text-capitalize">Congratulations You’ve successfully qualified for LDP 2 Program</h2>
                            </>
                        )}

                        <br />
                        <hr />
                        <Row>
                            <Col lg={6}>
                                <Form>
                                    <Row >
                                        <Col lg={12} className='mb-3'>
                                            <Form.Group controlId="formMobile">
                                                <Form.Label>Mobile <span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control
                                                    type="tel"
                                                    required
                                                    placeholder="Enter mobile number"
                                                    value={mobile}
                                                    onChange={(e) =>
                                                        setMobile(loginData1.mobile || e.target.value.replace(/[^0-9]/g, ''))
                                                    }
                                                    disabled={loginData1.mobile ? true : false}
                                                    pattern="[0-9]*"
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col lg={12} className='mb-3'>
                                            <Form.Group controlId="formAddress" className="mt-3">
                                                <Form.Label>Postal Address <span style={{ color: "red" }}>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter address"
                                                    value={address}
                                                    onChange={(e) => setAddress(e.target.value)}
                                                    disabled={loginData1.address ? true : false}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col lg={12} className='mb-3'>
                                            {getLdp2Data.qualifyCount >= 1 && (
                                                <Form.Group controlId="formPaymentOption" className="mt-3">
                                                    <Form.Label>Payment Option</Form.Label>
                                                    <Form.Select
                                                        value={paymentOption}
                                                        onChange={(e) => setPaymentOption(e.target.value)}
                                                        disabled={loginData1.paymentOption ? true : false}
                                                        className="py-2"
                                                    >
                                                        <option value="MNT">MNT</option>
                                                        <option value="USDT">USDT</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            )}
                                        </Col>

                                    </Row>
                                    <Row className='mb-3'>
                                        {getLdp2Data.qualifyCount >= 1 && <div className='text-left text-white d-flex flex-column gap-1'>
                                            <h6 className='text-secondary'>BNB Balance : <span className='text-light ' style={{ fontWeight: 800 }}>{currentTokenBalance['BNB'] || 0} BNB</span> </h6>
                                            <h6 className='text-secondary'>Current Balance : <span className='text-light ' style={{ fontWeight: 800 }}>{currentTokenBalance[paymentOption] || 0} {paymentOption}</span> </h6>
                                            <h6 className='text-secondary'>Payable Amount :  <span className='text-light ' style={{ fontWeight: 800 }}> {payableAmount || 0} {paymentOption}</span></h6>
                                        </div>}
                                        <Col className="d-flex justify-content-start mt-2">
                                            <Button
                                                variant="primary"
                                                onClick={() => handleClick(1)}
                                                className="me-2 btn-main2 px-4"
                                                disabled={paymentLoader}
                                            >Agree & Save
                                                {/* {paymentLoader ? <Spinner as="span" animation="border" size="sm" /> : 'Agree'} */}
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                onClick={() => handleClick(0)}
                                                className="btn-main2 px-3"
                                                disabled={paymentLoader}

                                            >Disagree
                                                {/* {paymentLoader ? <Spinner as="span" animation="border" size="sm" /> : 'Disagree'} */}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col lg={6}>
                                <div className='ldp_Qualifier text-center mt-3'>
                                    <img src='assets/images/ldp.png' width={`80%`} className='object-fit-contain' style={{ height: "416px" }} />

                                </div>
                            </Col>
                        </Row>


                        <br />
                    </div>
                </div>
            )}

        </>

    );
};

export default LDP2Qualifier;
